import React, { useContext, useState, useRef, useEffect } from "react";
import { TableCell as MuiTableCell } from "@mui/material";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { FaEye, FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import { FilePreviewModal } from "./FilePreviewModal";
import { metadataService } from "../../../../../../services/api";

const CustomTableCell = ({
  row,
  column,
  deasyFileKey,
  expandedRow,
  tableColumnWidths,
  processTagValue,
  onTagClick,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [editValues, setEditValues] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const editRef = useRef(null);
  const cellRef = useRef(null);
  const [editPosition, setEditPosition] = useState({ top: 0, left: 0 });

  const { deasyUserConfig, savedTags, deasyApiKey } = useContext(BaseContext);
  const vdbProfileName = deasyUserConfig?.vdbmConfig?.LastActive;
  const vdbConfig = deasyUserConfig?.vdbmConfig?.Configs[vdbProfileName];
  const vdbIsDataSource =
    vdbConfig?.type === "SharepointDataSourceManager" ||
    vdbConfig?.type === "S3DataSourceManager";

  // Calculate the position of the edit box
  useEffect(() => {
    if (isEditing && cellRef.current) {
      const cellRect = cellRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      // Calculate how much space is available to the right of the cell
      const spaceRight = viewportWidth - cellRect.right;

      // Determine the desired width of the edit box
      const desiredWidth = Math.max(300, cellRect.width * 1.5);

      // Determine the left position
      // If there's not enough space to the right, position it to the left
      let leftPosition = 0;
      if (spaceRight < desiredWidth) {
        // Align to the right edge of the cell, but don't go off-screen
        leftPosition = Math.min(0, -1 * (desiredWidth - cellRect.width));
      }

      setEditPosition({
        top: cellRect.height,
        left: leftPosition,
        width: desiredWidth,
      });
    }
  }, [isEditing, column, deasyFileKey]);

  // Handle clicks outside the edit area to cancel editing
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editRef.current &&
        !editRef.current.contains(event.target) &&
        isEditing
      ) {
        handleCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing]);

  // Focus the first input when editing starts
  useEffect(() => {
    if (isEditing && editRef.current) {
      const inputElement = editRef.current.querySelector("input, select");
      if (inputElement) {
        setTimeout(() => {
          inputElement.focus();
        }, 0);
      }
    }
  }, [isEditing]);

  if (!row || !column) return null;

  const columnWidth =
    column === deasyFileKey
      ? tableColumnWidths[deasyFileKey]
      : tableColumnWidths.default;

  const handleTagClick = (e, item) => {
    if (column === deasyFileKey) return;

    if (typeof onTagClick !== "function") {
      console.error("onTagClick is not a function");
      return;
    }
    e.stopPropagation();
    onTagClick(column, item, row[deasyFileKey]);
  };

  const handlePreviewClick = (e) => {
    e.stopPropagation();
    setShowPreviewModal(true);
  };

  const closePreviewModal = () => {
    setShowPreviewModal(false);
  };

  // Find the tag definition if this column represents a tag
  const tagDefinition =
    column !== deasyFileKey
      ? savedTags.find((tag) => tag.name === column)
      : null;
  const outputType = tagDefinition?.output_type;
  const availableValues = tagDefinition?.available_values;
  // const dateFormat = tagDefinition?.date_format;
  const maxValues = tagDefinition?.max_values;

  const upsertTagValue = async (tagValues) => {
    try {
      await metadataService.upsertMetadata(
        vdbProfileName,
        {
          [row[deasyFileKey]]: {
            [column]: {
              file_level: {
                values: tagValues,
              },
            },
          },
        },
        deasyApiKey,
      );
      return true;
    } catch (error) {
      console.error("Error updating tag value:", error);
      throw error;
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    if (column === deasyFileKey) return;

    setIsError(false);
    setErrorMessage("");

    // Set initial edit value(s) based on current value
    if (Array.isArray(row[column])) {
      setEditValues(row[column].slice());
    } else {
      setEditValue(row[column] || "");
    }

    setIsEditing(true);
  };

  const validateValue = (value) => {
    if (outputType === "number") {
      return !isNaN(Number(value));
    }
    if (outputType === "date") {
      return !isNaN(Date.parse(value));
    }
    if (
      availableValues &&
      availableValues.length === 2 &&
      availableValues.includes("Yes") &&
      availableValues.includes("No")
    ) {
      return value === "Yes" || value === "No";
    }
    return true;
  };

  const handleSave = async () => {
    // Validate based on output type
    let valuesToSave;
    let isValid = true;

    if (Array.isArray(row[column])) {
      // For multi-value fields
      valuesToSave = editValues.filter(Boolean);

      // Check if we exceed max values
      if (maxValues && valuesToSave.length > maxValues) {
        setIsError(true);
        setErrorMessage(`Maximum ${maxValues} values allowed`);
        return;
      }

      // Validate each value
      for (const val of valuesToSave) {
        if (!validateValue(val)) {
          isValid = false;
          break;
        }
      }
    } else {
      // For single value fields
      valuesToSave = editValue;
      isValid = validateValue(editValue);
    }

    if (!isValid) {
      setIsError(true);
      setErrorMessage(`Invalid value for ${outputType} type`);
      return;
    }

    // All validations passed, save the value
    setIsSaving(true);
    try {
      await upsertTagValue(
        Array.isArray(valuesToSave) ? valuesToSave : [valuesToSave],
      );

      // Update the row data locally to reflect the changes immediately
      // This creates a reference to the current row for immediate visual feedback
      if (Array.isArray(row[column])) {
        row[column] = [...valuesToSave.filter(Boolean)];
      } else {
        row[column] = valuesToSave;
      }

      setIsEditing(false);
      setIsSaving(false);
    } catch (error) {
      console.error("Error saving metadata:", error);
      setIsError(true);
      setErrorMessage("Failed to save changes. Please try again.");
      setIsSaving(false);
    }
  };

  const handleCancel = (e) => {
    e?.stopPropagation();
    setIsEditing(false);
    setIsError(false);
  };

  const handleInputChange = (e) => {
    e.stopPropagation();
    setEditValue(e.target.value);
  };

  const handleMultiValueChange = (index, value, e) => {
    if (e) e.stopPropagation();

    const newValues = [...editValues];
    newValues[index] = value;
    setEditValues(newValues);
  };

  const addNewValue = (e) => {
    if (e) e.stopPropagation();

    if (maxValues && editValues.length >= maxValues) {
      setIsError(true);
      setErrorMessage(`Maximum ${maxValues} values allowed`);
      return;
    }
    setEditValues([...editValues, ""]);
  };

  const removeValue = (index, e) => {
    if (e) e.stopPropagation();

    const newValues = [...editValues];
    newValues.splice(index, 1);
    setEditValues(newValues);
    setIsError(false);
  };

  const renderEditInterface = () => {
    if (column === deasyFileKey) return null;

    // If we have predefined options, show a dropdown
    if (availableValues && availableValues.length > 0) {
      if (Array.isArray(row[column])) {
        return (
          <div className="flex flex-col gap-2 w-full">
            {editValues.map((value, index) => (
              <div key={index} className="flex items-center gap-2 w-full group">
                <select
                  className="w-full p-2 border border-gray-300 rounded-md transition-all duration-200 text-sm bg-white focus:border-primary focus:ring-1 focus:ring-primary"
                  value={value}
                  onChange={(e) =>
                    handleMultiValueChange(index, e.target.value, e)
                  }
                  onClick={(e) => e.stopPropagation()}
                >
                  <option value="">Select a value</option>
                  {availableValues.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <button
                  className="p-1.5 text-gray-500 hover:text-red-500 transition-colors duration-200 bg-gray-100 rounded-full opacity-0 group-hover:opacity-100 focus:opacity-100"
                  onClick={(e) => removeValue(index, e)}
                  title="Remove value"
                >
                  <FaTimes size={14} />
                </button>
              </div>
            ))}
            {(!maxValues || editValues.length < maxValues) && (
              <button
                className="mt-1 px-3 py-1.5 text-xs bg-white text-primary hover:bg-primary-50 border border-primary-200 rounded-md transition-colors duration-200 flex items-center justify-center gap-1 self-start"
                onClick={(e) => addNewValue(e)}
              >
                <FaPlus size={10} />
                <span>Add value</span>
              </button>
            )}
          </div>
        );
      } else {
        return (
          <select
            className="w-full p-2 border border-gray-300 rounded-md transition-all duration-200 text-sm bg-white focus:border-primary focus:ring-1 focus:ring-primary"
            value={editValue}
            onChange={handleInputChange}
            onClick={(e) => e.stopPropagation()}
          >
            <option value="">Select a value</option>
            {availableValues.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      }
    }

    // For binary/boolean type
    if (outputType === "binary") {
      return (
        <div className="flex gap-3 mt-1">
          <label
            className="inline-flex items-center cursor-pointer"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="radio"
              className="h-4 w-4 text-primary focus:ring-primary border-gray-300"
              checked={editValue === "Yes"}
              onChange={(e) => {
                e.stopPropagation();
                setEditValue("Yes");
              }}
            />
            <span className="ml-2 text-sm">Yes</span>
          </label>
          <label
            className="inline-flex items-center cursor-pointer"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="radio"
              className="h-4 w-4 text-primary focus:ring-primary border-gray-300"
              checked={editValue === "No"}
              onChange={(e) => {
                e.stopPropagation();
                setEditValue("No");
              }}
            />
            <span className="ml-2 text-sm">No</span>
          </label>
        </div>
      );
    }

    // For date type
    if (outputType === "date") {
      return (
        <input
          type="date"
          className="w-full p-2 border border-gray-300 rounded-md transition-all duration-200 text-sm focus:border-primary focus:ring-1 focus:ring-primary"
          value={editValue}
          onChange={handleInputChange}
          onClick={(e) => e.stopPropagation()}
        />
      );
    }

    // For number type
    if (outputType === "number") {
      return (
        <input
          type="number"
          className="w-full p-2 border border-gray-300 rounded-md transition-all duration-200 text-sm focus:border-primary focus:ring-1 focus:ring-primary"
          value={editValue}
          onChange={handleInputChange}
          onClick={(e) => e.stopPropagation()}
        />
      );
    }

    // Default case: text input for single values or multiple text inputs for arrays
    if (Array.isArray(row[column])) {
      return (
        <div className="flex flex-col gap-2 w-full">
          {editValues.map((value, index) => (
            <div key={index} className="flex items-center gap-2 w-full group">
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md transition-all duration-200 text-sm min-h-[80px] resize-vertical focus:border-primary focus:ring-1 focus:ring-primary"
                value={value}
                onChange={(e) =>
                  handleMultiValueChange(index, e.target.value, e)
                }
                onClick={(e) => e.stopPropagation()}
                placeholder="Enter value"
              />
              <button
                className="p-1.5 text-gray-500 hover:text-red-500 transition-colors duration-200 bg-gray-100 rounded-full opacity-0 group-hover:opacity-100 focus:opacity-100"
                onClick={(e) => removeValue(index, e)}
                title="Remove value"
              >
                <FaTimes size={14} />
              </button>
            </div>
          ))}
          {(!maxValues || editValues.length < maxValues) && (
            <button
              className="mt-1 px-3 py-1.5 text-xs bg-white text-primary hover:bg-primary-50 border border-primary-200 rounded-md transition-colors duration-200 flex items-center justify-center gap-1 self-start"
              onClick={(e) => addNewValue(e)}
            >
              <FaPlus size={10} />
              <span>Add value</span>
            </button>
          )}
        </div>
      );
    } else {
      return (
        <textarea
          className="w-full p-2 border border-gray-300 rounded-md transition-all duration-200 text-sm min-h-[100px] resize-vertical focus:border-primary focus:ring-1 focus:ring-primary"
          value={editValue}
          onChange={handleInputChange}
          onClick={(e) => e.stopPropagation()}
          placeholder="Enter value"
        />
      );
    }
  };

  return (
    <>
      <MuiTableCell
        ref={cellRef}
        sx={{
          width: columnWidth,
          minWidth: columnWidth,
          maxWidth: columnWidth,
          padding: "8px 16px",
          whiteSpace: "normal",
          wordBreak: "break-word",
          position: "relative",
          "&:hover .cell-actions": {
            opacity: 1,
          },
          ...(column === deasyFileKey && {
            position: "sticky",
            left: 0,
            backgroundColor: "white",
            zIndex: 1,
            boxShadow: "4px 0 6px -6px rgba(0,0,0,0.1)",
          }),
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={`flex items-start ${column !== deasyFileKey ? "pr-8" : ""}`}
          style={{
            maxHeight: "120px",
            overflowY: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "&::WebkitScrollbar": {
              display: "none",
            },
          }}
        >
          {column === deasyFileKey && row.chunks?.length > 0 && (
            <span
              style={{
                fontSize: "0.75rem",
                color: "#6B7280",
                transition: "transform 0.2s",
                transform:
                  expandedRow === row[deasyFileKey] ? "rotate(90deg)" : "none",
                flexShrink: 0,
                marginTop: "2px",
                marginRight: "8px",
              }}
            >
              ▶
            </span>
          )}

          {/* Display normal content (not editing) */}
          {!isEditing && (
            <div className="flex-1">
              {Array.isArray(row[column]) ? (
                <div className="flex flex-wrap gap-1.5">
                  {row[column].map((item, index) => (
                    <span
                      key={index}
                      className={`bg-gray-50 px-2.5 py-1.5 rounded-md text-sm font-medium shadow-sm border border-gray-100
                        ${
                          column !== deasyFileKey
                            ? "cursor-pointer hover:bg-gray-100 hover:border-gray-200 transition-all duration-200"
                            : ""
                        }`}
                      onClick={(e) => handleTagClick(e, item)}
                    >
                      {processTagValue(item)}
                    </span>
                  ))}
                </div>
              ) : processTagValue(row[column]) === "Untagged" ? (
                <div className="bg-red-50 text-red-600 px-2.5 py-1.5 rounded-md text-xs font-medium shadow-sm border border-red-100">
                  Untagged
                </div>
              ) : (
                <span
                  className={`break-words whitespace-pre-wrap 
                    ${
                      column !== deasyFileKey
                        ? "cursor-pointer hover:bg-gray-50 transition-colors duration-200"
                        : ""
                    } 
                    px-2.5 py-1.5 rounded-md text-sm`}
                  onClick={(e) => handleTagClick(e, row[column])}
                >
                  {processTagValue(row[column])}
                </span>
              )}
            </div>
          )}

          {/* Action buttons */}
          <div
            className={`cell-actions absolute top-2 right-2 flex gap-1 transition-opacity duration-200 ${isHovered ? "opacity-100" : "opacity-0"}`}
          >
            {column !== deasyFileKey && (
              <button
                className="p-1.5 text-gray-400 hover:text-primary transition-colors duration-200 bg-white hover:bg-gray-50 shadow-md border border-gray-100 rounded-full items-center justify-center"
                onClick={handleEditClick}
                title="Edit value"
              >
                <BiSolidEdit size={12} />
              </button>
            )}

            {vdbIsDataSource && column === deasyFileKey && (
              <button
                className="p-1.5 text-gray-400 hover:text-primary transition-colors duration-200 bg-white hover:bg-gray-50 shadow-sm border border-gray-100 rounded-full flex-shrink-0 flex items-center justify-center"
                onClick={handlePreviewClick}
                title="Preview OCR'd text"
              >
                <FaEye size={12} />
              </button>
            )}
          </div>
        </div>

        {/* Floating edit box */}
        {isEditing && column !== deasyFileKey && (
          <div
            className="absolute z-[1000] shadow-lg animate-fadeIn"
            ref={editRef}
            style={{
              top: `${editPosition.top}px`,
              left: `${editPosition.left}px`,
              width: `${editPosition.width}px`,
              zIndex: 9999,
            }}
          >
            <div
              className="bg-white p-4 rounded-lg border border-gray-200 shadow-xl max-h-[400px] overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-3">
                <h3 className="font-medium text-gray-700">Edit {column}</h3>
                <button
                  className="p-1 text-gray-400 hover:text-gray-600 rounded-full"
                  onClick={handleCancel}
                >
                  <FaTimes size={14} />
                </button>
              </div>

              {renderEditInterface()}

              {isError && (
                <div className="text-red-500 text-xs mt-3 bg-red-50 p-2 rounded-md">
                  {errorMessage}
                </div>
              )}

              <div className="flex justify-end mt-4 gap-2">
                <button
                  className={`px-3 py-1.5 rounded-md transition-all duration-200 flex items-center gap-1.5 text-xs ${
                    isSaving
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-primary text-white hover:bg-primary-dark"
                  }`}
                  onClick={handleSave}
                  title="Save"
                  disabled={isSaving}
                >
                  <FaCheck size={12} />
                  <span>Save</span>
                </button>
                <button
                  className="px-3 py-1.5 bg-gray-100 text-gray-600 hover:bg-gray-200 rounded-md transition-all duration-200 flex items-center gap-1.5 text-xs"
                  onClick={handleCancel}
                  title="Cancel"
                >
                  <FaTimes size={12} />
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </MuiTableCell>

      {showPreviewModal && (
        <FilePreviewModal
          filename={row[deasyFileKey]}
          closePreviewModal={closePreviewModal}
        />
      )}
    </>
  );
};

// Add custom CSS for animations and transitions
const style = document.createElement("style");
style.textContent = `
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-5px); }
    to { opacity: 1; transform: translateY(0); }
  }
  .animate-fadeIn {
    animation: fadeIn 0.2s ease-out forwards;
  }
`;
document.head.appendChild(style);

export default CustomTableCell;
