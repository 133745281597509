import React, { useState, useEffect } from "react";
import { tokenService } from "../../services/api";

const FeedbackModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    title: "",
    body: "",
    severity: "Low",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Reset form validation state when modal opens or closes
  useEffect(() => {
    if (isOpen) {
      setIsFormValid(!!formData.title.trim() && !!formData.body.trim());
    }
  }, [isOpen, formData.title, formData.body]);

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);

    // Check if form is valid (both fields have content)
    setIsFormValid(
      !!updatedFormData.title.trim() && !!updatedFormData.body.trim(),
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    try {
      // Validate form
      if (!formData.title.trim() || !formData.body.trim()) {
        setError("Both title and feedback are required");
        setIsSubmitting(false);
        return;
      }

      await tokenService.sendFeedback(
        formData.title,
        formData.body,
        formData.severity,
      );

      // Show success message
      setSuccess(true);

      // Reset form after 3 seconds and close the modal
      setTimeout(() => {
        setFormData({ title: "", body: "", severity: "Low" });
        setSuccess(false);
        onClose();
      }, 3000);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setError("Failed to submit feedback. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center z-50 transition-opacity duration-300">
      <div className="bg-gradient-to-b from-[#1a1f2b] to-[#232936] rounded-lg p-8 w-full max-w-md shadow-xl border border-gray-700/50">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <div className="bg-emerald-500/20 p-2 rounded-full mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-emerald-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold text-emerald-400">
              Submit Feedback
            </h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-emerald-300 transition-colors p-2 hover:bg-emerald-500/10 rounded-lg focus:outline-none"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {!success ? (
          <>
            <div className="bg-blue-900/20 border-l-4 border-blue-500 p-4 rounded mb-6">
              <p className="text-blue-100 text-left">
                We value your feedback! Let us know how we can improve your
                Deasy experience or report any issues you've encountered.
              </p>
            </div>

            {error && (
              <div className="bg-red-900/40 border-l-4 border-red-500 text-red-200 p-4 rounded mb-6 flex items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-red-400 mr-2 mt-0.5 flex-shrink-0"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>{error}</span>
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="space-y-5">
                <div>
                  <label className="block text-gray-300 mb-1 font-medium text-left">
                    Title *
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    className="w-full bg-[#2d3544] border border-gray-600 rounded-md p-3 text-white focus:ring-2 focus:ring-emerald-500 focus:border-transparent focus:outline-none transition"
                    placeholder="Brief description of your feedback"
                    required
                  />
                </div>

                <div>
                  <label className="block text-gray-300 mb-1 font-medium text-left">
                    Feedback *
                  </label>
                  <textarea
                    name="body"
                    value={formData.body}
                    onChange={handleChange}
                    className="w-full bg-[#2d3544] border border-gray-600 rounded-md p-3 text-white focus:ring-2 focus:ring-emerald-500 focus:border-transparent focus:outline-none transition h-32 resize-none"
                    placeholder="Please provide detailed feedback..."
                    required
                  />
                </div>

                <div>
                  <label className="block text-gray-300 mb-1 font-medium text-left">
                    Severity
                  </label>
                  <div className="relative">
                    <select
                      name="severity"
                      value={formData.severity}
                      onChange={handleChange}
                      className="w-full bg-[#2d3544] border border-gray-600 rounded-md p-3 pl-8 text-white focus:ring-2 focus:ring-emerald-500 focus:border-transparent focus:outline-none transition appearance-none"
                    >
                      <option
                        value="Low"
                        className="bg-[#2d3544] text-green-400"
                      >
                        Low
                      </option>
                      <option
                        value="Medium"
                        className="bg-[#2d3544] text-yellow-400"
                      >
                        Medium
                      </option>
                      <option
                        value="High"
                        className="bg-[#2d3544] text-red-400"
                      >
                        High
                      </option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-400">
                      <svg
                        className="h-4 w-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <div
                        className={`w-2 h-2 rounded-full ${
                          formData.severity === "Low"
                            ? "bg-green-400"
                            : formData.severity === "Medium"
                              ? "bg-yellow-400"
                              : "bg-red-400"
                        }`}
                      />
                    </div>
                  </div>
                  <p className="text-gray-400 text-xs mt-1">
                    How important is this feedback or issue to you?
                  </p>
                </div>
              </div>

              <div className="mt-8 flex flex-col justify-start items-start">
                <button
                  type="submit"
                  disabled={isSubmitting || !isFormValid}
                  className={`py-2.5 px-5 rounded-md font-medium flex items-center transition-colors duration-200 focus:outline-none ${
                    isFormValid
                      ? "bg-emerald-500 hover:bg-emerald-600 text-white"
                      : "bg-gray-500/50 text-gray-300 cursor-not-allowed"
                  }`}
                >
                  {isSubmitting ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Submitting...
                    </>
                  ) : (
                    "Submit Feedback"
                  )}
                </button>
                {!isFormValid && (
                  <p className="text-gray-400 text-xs mt-2">
                    Both title and feedback are required to submit
                  </p>
                )}
              </div>
            </form>
          </>
        ) : (
          <div className="text-center py-8">
            <div className="bg-emerald-500/20 p-3 rounded-full mx-auto mb-4 inline-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-emerald-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h3 className="text-xl font-medium text-emerald-400 mb-2">
              Thank You!
            </h3>
            <p className="text-gray-300">
              Your feedback has been submitted successfully.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackModal;
