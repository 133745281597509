import { AiOutlinePlusCircle, AiFillPlusCircle } from "react-icons/ai";
import { Position, Handle } from "reactflow";
import { useContext, useState, useEffect, useRef } from "react";
import { NodeInfoModal } from "./NodeInfoModal";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { useDrop } from "react-dnd";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { createPortal } from "react-dom";
import { ValueSelectionModal } from "./AvailableValueSelector";
import { FiTrash2, FiPlus } from "react-icons/fi";
import TestTagButton from "../../../../../utils/TestTagButton";
import {
  classifyAll,
  useJobProgress,
} from "../../../../../MainContent/DeasyConfig/ConfigElements/useJobProgress";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import {
  findNodeStats,
  RerunConfirmationModal,
  DeleteConfirmationModal,
  handleConfirmNodeDelete,
  DeleteValueNodeModal,
} from "./GraphNodeUtils";
import TagEditor from "../../../../SavedTags/components/TagEditor/TagEditor";
import { StandardizeValuesPanel } from "../Standardization/StandardizeValuesPanel";
import * as Sentry from "@sentry/react";
import { TAG_TYPES } from "../GraphUtils";
import { TagScore } from "../../../../../utils/TagScore";

// Custom node components
export const TagNode = ({ data }) => {
  const {
    updatedTags,
    setUpdatedTags,
    savedTags,
    deasyUserConfig,
    vdbFilesCount,
    vdbLastActiveProfileName,
  } = useContext(BaseContext);
  const { hierarchyStats, uniqueTags, tagScores } = useContext(GraphContext);
  const showControls = !data.hideControls;

  // Find the tag's available values with null check..
  const tagValues =
    (savedTags || []).find((t) => t.name === data.label)?.available_values ||
    [];
  const hasMultipleValues = tagValues.length > 1;

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTunedModal, setShowTunedModal] = useState(false);
  const [showRerunOptions, setShowRerunOptions] = useState(false);
  const [isRerunning, setIsRerunning] = useState(false);
  const [jobId, setJobId] = useState(null);
  const rerunButtonRef = useRef(null);
  const rerunDropdownRef = useRef(null);
  const [showStandardizeDropdown, setShowStandardizeDropdown] = useState(false);
  const standardizeButtonRef = useRef(null);
  const [tagStats, setTagStats] = useState(null);
  const [isLoadingStats, setIsLoadingStats] = useState(false);

  const tagBody = savedTags.find((tag) => tag.name === data.label);
  const nodeStats = findNodeStats(hierarchyStats, data.nodePath);
  const tagDetails = savedTags.find((tag) => tag.name === data.label);

  const {
    vdbmConfig: { Configs: vdbmConfigs },
    llmConfig: { LastActive: llmLastActive },
    deasyApiKey,
  } = deasyUserConfig;
  const vectorDBConfiguration = vdbmConfigs[vdbLastActiveProfileName];

  // Get tag score from context
  const tagScore = data.label !== "<ANY>" ? tagScores[data.label] : null;

  const [{ isOver }, drop] = useDrop({
    accept: "TAG",
    drop: (item, monitor) => {
      const tag = item.tag;
      if (tag) {
        data.onAddNode(data, "tag");
        data.handleAddNodeApplied(data, tag);
      }
      return { dropped: true };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [showValueModal, setShowValueModal] = useState(false);

  const questionTagUpdated = updatedTags[data.label];

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);
  const [hasShownSuccess, setHasShownSuccess] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [tagInEditor, setTagInEditor] = useState(null);
  const [isTagEditorCollapsed, setIsTagEditorCollapsed] = useState(false);
  const [activeTagEditorSection, setActiveTagEditorSection] =
    useState("general");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        rerunDropdownRef.current &&
        !rerunDropdownRef.current.contains(event.target) &&
        !rerunButtonRef.current.contains(event.target)
      ) {
        setShowRerunOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Add effect for standardize dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        standardizeButtonRef.current &&
        !standardizeButtonRef.current.contains(event.target)
      ) {
        setShowStandardizeDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fetch tag statistics for standardization
  const fetchTagStatistics = async () => {
    try {
      if (!data.label) return;

      setIsLoadingStats(true);

      const { metadataService } = await import(
        "../../../../../../services/api"
      );
      const { nodePathToConditions } = await import("../GraphUtils");

      const response = await metadataService.getTagStatistics(
        vdbLastActiveProfileName,
        [data.label],
        deasyApiKey,
        nodePathToConditions(data.nodePath),
      );

      setTagStats(response.data.tag_statistics[data.label]);
      return response.data.tag_statistics[data.label];
    } catch (error) {
      console.error("Error fetching tag statistics:", error);
      throw error;
    } finally {
      setIsLoadingStats(false);
    }
  };

  // Fetch tag statistics when standardize dropdown is opened
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (showStandardizeDropdown && !tagStats) {
      fetchTagStatistics();
    }
  }, [showStandardizeDropdown, tagStats]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tagBody) {
      setTagInEditor(tagBody);
    }
  }, [tagBody]);

  const handleRerun = (option) => {
    const numberOfFilesToClassify =
      option === "node"
        ? nodeStats?.file_count || 0
        : vdbFilesCount?.total_files || 0;

    setConfirmationDetails({
      tagCount: 1,
      tags: [data.label],
      fileCount: numberOfFilesToClassify,
      isRootSelection: option !== "node",
      option,
    });
    setShowConfirmDialog(true);
  };

  const handleConfirmedRerun = () => {
    setShowConfirmDialog(false);
    if (!confirmationDetails) return;
    setHasShownSuccess(false);
    setShowRerunOptions(false);
    setIsRerunning(true);
    try {
      const jobID = uuidv4();
      setJobId(jobID);
      classifyAll(
        vdbLastActiveProfileName, // vdbmLastActive
        llmLastActive, // llmLastActive
        deasyApiKey, // deasyApiKey
        data, // selectedNodeData
        setJobId, // setJobId
        isRerunning, // isRerunning
        setIsRerunning, // setIsRerunning
        vdbFilesCount, // vdbFilesCount
        { [data.label]: tagBody }, // tags
        null, // hierarchy
        confirmationDetails.option === "node", // useConditions
        true, // overwrite
      );
    } catch (error) {
      console.error("Error rerunning:", error);
      Sentry.captureException(error);
      setIsRerunning(false);
      setHasShownSuccess(false);
    }
  };

  const { progress: classifyProgress } = useJobProgress({
    jobId,
    isRunning: isRerunning,
    onComplete: async () => {
      setIsRerunning(false);
      setJobId(null);
      if (!hasShownSuccess) {
        const fileCount = confirmationDetails?.fileCount || 0;
        toast.success(
          `Successfully re-ran tag "${data.label}" on ${fileCount.toLocaleString()} file${fileCount === 1 ? "" : "s"}`,
        );
        setHasShownSuccess(true);
      }
    },
    onError: (error) => {
      toast.error(error.response?.data?.detail || "Re-running failed");
      setIsRerunning(false);
      setJobId(null);
      setHasShownSuccess(false);
    },
    apiKey: deasyApiKey,
    successMessage: "Re-running completed successfully",
  });

  const handleCloseEditor = () => {
    if (hasUnsavedChanges) {
      if (
        window.confirm(
          "You have unsaved changes. Are you sure you want to close?",
        )
      ) {
        setIsEditing(false);
        setHasUnsavedChanges(false);
      }
    } else {
      setIsEditing(false);
    }
  };

  return (
    <div
      ref={drop}
      className={`flex flex-col items-center ${
        isOver ? "bg-gray-100 rounded-lg p-2" : ""
      } overflow-visible group`}
      style={{ position: "relative", zIndex: 0 }}
      onClick={(e) => e.stopPropagation()}
    >
      {showControls && (
        <div className="absolute -top-24 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out bg-white shadow-lg rounded-xl border border-gray-100 p-4 flex flex-col items-center max-w-[320px] backdrop-blur-sm bg-opacity-95">
          <div className="flex items-center justify-between w-full gap-4">
            {/* Test Button */}
            <div className="flex flex-col items-center">
              <TestTagButton tagId={data.label} id={`test-tag-${data.label}`} />
            </div>

            {/* Standardize Button */}
            <div className="flex flex-col items-center">
              <button
                ref={standardizeButtonRef}
                onClick={async (e) => {
                  e.stopPropagation();
                  if (!tagStats) {
                    try {
                      await fetchTagStatistics();
                    } catch (error) {
                      console.error("Error fetching tag statistics:", error);
                    }
                  }
                  setShowStandardizeDropdown(true);
                }}
                className="flex flex-col items-center justify-center gap-1 text-gray-600 hover:text-gray-800 transition-all duration-200 p-1.5 rounded-lg hover:bg-gray-50 shadow-sm"
                disabled={isLoadingStats}
                title="Standardize Values"
              >
                {isLoadingStats ? (
                  <div className="w-3.5 h-3.5 border-2 border-gray-600 border-t-transparent rounded-full animate-spin" />
                ) : (
                  <svg
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path d="M21 10H3"></path>
                    <path d="M21 6H3"></path>
                    <path d="M21 14H3"></path>
                    <path d="M21 18H3"></path>
                  </svg>
                )}
                <span className="text-xs font-medium">Standardize</span>
              </button>
            </div>

            {/* Run Button */}
            <div className="flex flex-col items-center scale-110 z-10">
              <button
                ref={rerunButtonRef}
                onClick={() => setShowRerunOptions(!showRerunOptions)}
                className="flex items-center justify-center gap-2 text-white bg-primary hover:bg-primary-dark transition-all duration-200 px-4 py-2 rounded-lg shadow-md transform hover:scale-105"
                disabled={isRerunning}
                title={
                  questionTagUpdated
                    ? "This tag has been updated with new available values. Re-run files to tag with the new available values."
                    : "Re-run tagging"
                }
              >
                {questionTagUpdated && (
                  <div className="absolute -top-1 -right-1">
                    <span className="relative flex h-2 w-2">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-300 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2 w-2 bg-orange-500"></span>
                    </span>
                  </div>
                )}
                {isRerunning ? (
                  <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  <svg
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <polygon
                      points="10 8 16 12 10 16 10 8"
                      fill="currentColor"
                      stroke="none"
                    />
                  </svg>
                )}
                <span className="text-sm font-semibold">Run</span>
              </button>
              {showRerunOptions && (
                <div
                  ref={rerunDropdownRef}
                  className="absolute top-full mt-2 right-0 w-48 bg-white border border-gray-100 shadow-lg rounded-lg py-1 z-[1000] animate-slideDown"
                  style={{
                    transformOrigin: "top center",
                    animation: "slideDown 0.2s ease-out forwards",
                  }}
                >
                  <button
                    onClick={() => handleRerun("node")}
                    className="w-full text-left px-3 py-2 text-sm hover:bg-gray-50 hover:text-gray-800 transition-colors flex items-center gap-2"
                  >
                    <svg
                      className="w-3.5 h-3.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <circle cx="12" cy="12" r="4"></circle>
                    </svg>
                    This node
                  </button>
                  <button
                    onClick={() => handleRerun("connector")}
                    className="w-full text-left px-3 py-2 text-sm hover:bg-gray-50 hover:text-gray-800 transition-colors flex items-center gap-2"
                  >
                    <svg
                      className="w-3.5 h-3.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M18 14v6"></path>
                      <path d="M6 4v6"></path>
                      <path d="M12 13a5 5 0 0 0 5-5V4"></path>
                      <path d="M12 19a5 5 0 0 1-5-5V4"></path>
                    </svg>
                    All files
                  </button>
                </div>
              )}
            </div>

            {/* Delete Button */}
            <div className="flex flex-col items-center">
              <button
                className="flex flex-col items-center justify-center gap-1 text-gray-600 hover:text-red-500 transition-all duration-200 p-1.5 rounded-lg hover:bg-red-50 shadow-sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteModal(true);
                }}
                title="Delete Tag"
              >
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                </svg>
                <span className="text-xs font-medium">Delete</span>
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Hidden TestTagButton for functionality */}
      <div className="hidden">
        <TestTagButton tagId={data.label} id={`test-tag-${data.label}`} />
      </div>

      {showStandardizeDropdown &&
        createPortal(
          <div
            className="fixed inset-0 bg-black/30 flex items-center justify-center z-[1000]"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseMove={(e) => e.stopPropagation()}
          >
            <StandardizeValuesPanel
              isOpen={showStandardizeDropdown}
              onClose={() => setShowStandardizeDropdown(false)}
              valueDistribution={tagStats?.value_distribution}
              onValueSelect={(values, stats) => {}}
              buttonRef={standardizeButtonRef}
              tagsDetails={tagDetails}
              className="w-full"
            />
          </div>,
          document.body,
        )}

      {isRerunning && (
        <div className="absolute -bottom-16 left-1/2 transform -translate-x-1/2 bg-white shadow-lg rounded-md border border-gray-200 p-2.5 w-52 z-10">
          <div className="space-y-2">
            <div className="flex items-center justify-between text-xs text-gray-600 mb-1">
              <span className="font-medium">Processing...</span>
              <span className="font-medium">
                {Math.round(classifyProgress * 100)}%
              </span>
            </div>
            <div className="w-full bg-gray-100 rounded-full h-2 overflow-hidden">
              <div
                className="bg-primary h-full transition-all duration-300 ease-in-out"
                style={{ width: `${classifyProgress * 100}%` }}
              />
            </div>
          </div>
        </div>
      )}

      <RerunConfirmationModal
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirmedRerun}
        data={data}
        tagBody={tagBody}
        nodeStats={nodeStats}
        vdbFilesCount={vdbFilesCount}
        confirmationDetails={confirmationDetails}
      />

      <div
        className="flex flex-col items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="max-w-[200px]">
          <div
            className={`rounded-md border px-4 py-2 transition-colors shadow-md
              ${questionTagUpdated && "border-orange-300 bg-orange-100"}
              ${
                !questionTagUpdated &&
                data.label !== "<ANY>" &&
                uniqueTags &&
                uniqueTags.includes(data.label)
                  ? "border-primary/60 shadow-primary/10 bg-gradient-to-br from-primary/5 to-primary/10 font-medium"
                  : "border-gray-600 bg-gray-200"
              }
            `}
          >
            {questionTagUpdated && (
              <div className="absolute -top-2.5 -right-2.5">
                <span className="relative flex h-4 w-4">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-300 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-4 w-4 bg-orange-500"></span>
                </span>
              </div>
            )}
            <div className="flex flex-col gap-1">
              <div className="flex flex-row justify-between items-center gap-3">
                <div className="font-medium text-gray-800 text-lg text-left break-words">
                  {data.label === "<ANY>" ? "Open-ended" : data.label}
                </div>
                <div className="flex items-center gap-1">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditing(true);
                    }}
                    className="p-1.5 rounded-md text-gray-500 hover:text-gray-700 hover:bg-gray-100 
                             transition-all duration-150 ease-in-out flex-shrink-0"
                    title="Edit Tag"
                  >
                    <svg
                      className="w-4 h-4"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowModal(true);
                    }}
                    className="p-1.5 rounded-md text-gray-500 hover:text-gray-700 hover:bg-gray-100 
                             transition-all duration-150 ease-in-out flex-shrink-0"
                    title="View Statistics & Distribution"
                  >
                    <svg
                      className="w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M18 20V10" />
                      <path d="M12 20V4" />
                      <path d="M6 20v-6" />
                    </svg>
                  </button>
                </div>
              </div>
              {tagScore !== null && data.label !== "<ANY>" && (
                <div className="flex items-start">
                  <TagScore tagScore={tagScore} />
                </div>
              )}
            </div>
          </div>
        </div>

        {showControls &&
          hasMultipleValues &&
          tagBody.output_type !== TAG_TYPES.BINARY && (
            <div className="relative mt-2">
              <button
                className="flex items-center gap-2 text-primary hover:text-primary-dark
                         bg-white hover:bg-gray-50 border border-gray-300 hover:border-primary 
                         shadow-sm hover:shadow-md px-3 py-1.5 rounded-md transition-all duration-150"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowValueModal(true);
                }}
              >
                <FiPlus className="w-4 h-4" />
                <span className="text-sm font-semibold">Add Output Value</span>
              </button>

              {showValueModal && (
                <ValueSelectionModal
                  onClose={() => setShowValueModal(false)}
                  onSelect={(value) => {
                    data.onAddAvailableValue?.(data, value);
                  }}
                  onAddNew={(value) => {
                    data.onAddAvailableValue?.(data, value);
                  }}
                  availableValues={tagValues}
                  selectedTag={data.label}
                />
              )}
            </div>
          )}

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
      </div>

      {showModal && (
        <NodeInfoModal
          nodeData={data}
          onClose={() => setShowModal(false)}
          setUpdatedTags={setUpdatedTags}
          vectorDBConfiguration={vectorDBConfiguration}
          deasyApiKey={deasyApiKey}
        />
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          nodeName={data.label}
          onConfirm={() => {
            handleConfirmNodeDelete(setShowDeleteModal, data);
          }}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}

      {showTunedModal &&
        createPortal(
          <div
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="bg-white rounded-lg p-6 max-w-sm w-96 shadow-xl">
              <h3 className="text-lg font-semibold mb-4">Fine-tuned Tag</h3>
              <p className="mb-6 text-gray-600">
                This tag has been fine-tuned to improve its accuracy and
                performance.
              </p>
              <div className="flex justify-end">
                <button
                  className="px-4 py-2 rounded-md text-gray-600 hover:bg-gray-50 border border-gray-200"
                  onClick={() => setShowTunedModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>,
          document.body,
        )}

      {isEditing &&
        createPortal(
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000] animate-fadeIn"
            onClick={(e) => {
              if (e.target === e.currentTarget) handleCloseEditor();
            }}
          >
            <div
              className="bg-white relative rounded-lg p-6 w-[78vw] max-h-[90vh] overflow-y-auto mx-4 animate-slideIn"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold text-gray-900">
                  Edit Tag: {data.label}
                </h2>
                <button
                  onClick={handleCloseEditor}
                  className="text-gray-400 hover:text-gray-600 p-1"
                >
                  ✕
                </button>
              </div>

              <TagEditor
                collapseDirection="horizontal"
                disableCollapse={true}
                showHeader={false}
                tagInEditor={tagInEditor}
                setTagInEditor={setTagInEditor}
                isTagEditorCollapsed={isTagEditorCollapsed}
                setIsTagEditorCollapsed={setIsTagEditorCollapsed}
                activeTagEditorSection={activeTagEditorSection}
                setActiveTagEditorSection={setActiveTagEditorSection}
                context="modal"
                editingExistingTag={true}
                onSave={() => {
                  setHasUnsavedChanges(false);
                  setIsEditing(false);
                  // Refresh the node's data if needed
                  if (data.onRefresh) {
                    data.onRefresh();
                  }
                }}
                onFormChange={() => setHasUnsavedChanges(true)}
              />
            </div>
          </div>,
          document.body,
        )}

      <style jsx global>{`
        @keyframes slideDown {
          from {
            opacity: 0;
            transform: translateY(-10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .animate-slideDown {
          animation: slideDown 0.2s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export const ValueNode = ({ data }) => {
  const {
    selectedNodeData,
    hierarchyStats,
    selectedNodes,
    setSelectedNodes,
    activeOverlappingNode,
    discoveryGraphData,
  } = useContext(GraphContext);
  const { isConfigRefreshing } = useContext(BaseContext);
  const nodeIsSelected =
    selectedNodeData !== null && selectedNodeData?.id === data.id;

  const showControls = !data.hideControls;
  const isSelected = selectedNodes.some((node) => node.id === data.id);

  const nodeStats = findNodeStats(hierarchyStats, data.nodePath);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [childNodes, setChildNodes] = useState([]);

  const handleRemoveClick = () => {
    // Find child nodes from the graph data
    const currentPath = data.nodePath;
    let current = discoveryGraphData;

    // Navigate to current node in the graph data
    for (const pathItem of currentPath) {
      current = current[pathItem];
    }

    // Get all child nodes
    const children = [];
    if (current && typeof current === "object") {
      Object.keys(current).forEach((key) => {
        if (key !== "TagAvailableValues") {
          children.push({
            label: key,
            path: [...currentPath, key].join("/"),
          });
        }
      });
    }

    setChildNodes(children);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    data.onRemoveAvailableValue(data);
  };

  const handleNodeClick = (e, nodeStats) => {
    e.stopPropagation();
    e.preventDefault();

    // Don't allow selection if it's an open-ended node
    if (data.label === "<ANY>") return;

    setSelectedNodes((prev) => {
      const newSelection = [...prev];
      const nodeIndex = newSelection.findIndex((node) => node.id === data.id);

      if (nodeIndex !== -1) {
        // Remove just this node
        return newSelection.filter((node) => node.id !== data.id);
      }
      // If node is not selected, select only this node
      else {
        // Add only the clicked node
        return [
          ...newSelection,
          {
            id: data.id,
            data: {
              nodePath: data.nodePath,
              label: data.label,
            },
            type: data.type,
            stats: {
              fileCount: nodeStats?.file_count || 0,
              percentage: nodeStats?.percentage || 0,
            },
          },
        ];
      }
    });
  };

  // Add click handler to the container div to prevent bubbling
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  const [{ isOver }, drop] = useDrop({
    accept: "TAG",
    drop: (item, monitor) => {
      const tag = item.tag;
      if (tag) {
        data.onAddNode(data, "value");
        data.handleAddNodeApplied(data, tag);
      }
      return { dropped: true };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  // Add useEffect for click outside handling
  useEffect(() => {
    const handleClickOutside = (e) => {
      // Only proceed if there are any selected nodes
      if (selectedNodes.length > 0) {
        // Check if click was outside of any ValueNode containers
        const valueNodeContainers = document.querySelectorAll(
          ".value-node-container",
        );
        let clickedInside = false;
        valueNodeContainers.forEach((container) => {
          if (container.contains(e.target)) {
            clickedInside = true;
          }
        });

        if (!clickedInside) {
          // Clear all selections
          setSelectedNodes([]);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [selectedNodes, setSelectedNodes]); // Removed data.id from dependencies since we handle all nodes

  return (
    <div
      ref={drop}
      onClick={handleContainerClick}
      className={`value-node-container flex flex-col items-center ${isOver ? "bg-[#f8f9fa] rounded-lg p-2" : ""}`}
    >
      <div className="flex flex-col items-center">
        <div className="flex gap-2"></div>
        <div
          onClick={(e) => handleNodeClick(e, nodeStats)}
          className={`
            rounded-sm border py-2
            ${data.label === "<ANY>" ? "px-4 border-dashed" : "pl-4 pr-2"}
            ${
              nodeStats?.file_count > 0 && data.label !== "<ANY>"
                ? "border-primary/40 bg-[#f0fff4]"
                : "border-gray-300 bg-white"
            }
            ${data.label === "<ANY>" ? "" : "cursor-pointer"}
            ${data.id === activeOverlappingNode ? "ring-8" : ""}
            ${
              isSelected && data.label !== "<ANY>"
                ? "ring-2 ring-primary ring-offset-2"
                : nodeStats?.file_count > 0
                  ? "hover:border-[#38a169] hover:bg-[#f0fff4]"
                  : "hover:bg-gray-50"
            }
          `}
        >
          <div className="max-w-[10vw] flex flex-row justify-between items-center gap-3 break-normal">
            <div
              className={`font-medium ${
                data.label === "<ANY>"
                  ? "text-gray-500 italic"
                  : nodeStats?.file_count > 0
                    ? "text-primary"
                    : "text-gray-600"
              } text-left flex-grow`}
            >
              {data.label === "<ANY>" ? "Open-ended" : data.label}
            </div>
            {showControls && data.label !== "<ANY>" && (
              <button
                className="p-1.5 rounded-md text-gray-500 hover:text-gray-700 hover:bg-gray-100 
                           transition-all duration-150 ease-in-out self-start"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveClick();
                }}
              >
                {nodeIsSelected ? (
                  <FiTrash2 size={10} className="text-red-500" />
                ) : (
                  <FiTrash2 size={10} className="text-gray-500" />
                )}
              </button>
            )}
          </div>
          {data.label !== "<ANY>" && (
            <div
              className={`text-sm ${nodeStats?.file_count > 0 ? "text-primary" : "text-gray-500"} text-left w-full mt-1`}
            >
              {isConfigRefreshing ? (
                <div className="flex items-center gap-1">
                  <div className="w-3 h-3 border-2 border-gray-300 border-t-transparent rounded-full animate-spin" />
                </div>
              ) : (
                <>
                  <div className="flex flex-col gap-0.5">
                    <div
                      className={`${nodeStats?.file_count > 0 ? "font-medium" : ""} flex items-center gap-1`}
                    >
                      <span className="text-gray-500">Files:</span>
                      <span
                        className={
                          nodeStats?.file_count > 0
                            ? "text-primary font-semibold"
                            : "text-gray-600"
                        }
                      >
                        {nodeStats
                          ? nodeStats.file_count.toLocaleString()
                          : "0"}
                      </span>
                    </div>
                    <div
                      className={`${nodeStats?.file_count > 0 ? "font-medium" : ""} flex items-center gap-1`}
                    >
                      <span className="text-gray-500">Coverage:</span>
                      <span
                        className={
                          nodeStats?.file_count > 0
                            ? "text-primary font-semibold"
                            : "text-gray-600"
                        }
                      >
                        {nodeStats
                          ? nodeStats.percentage.toFixed(1) + "%"
                          : "0.0%"}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="flex gap-2">
          {showControls && data.label !== "<ANY>" && (
            <button
              className={`text-primary hover:scale-110 transition-all ${
                nodeIsSelected
                  ? "bg-primary text-white rounded-full shadow-lg animate-pulse"
                  : "hover:bg-primary/10 rounded-full"
              }`}
              onClick={() => data.onAddNode(data, "value")}
            >
              {nodeIsSelected ? (
                <AiFillPlusCircle size={28} className="p-0.5" />
              ) : (
                <AiOutlinePlusCircle size={24} />
              )}
            </button>
          )}
        </div>
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
      </div>

      <DeleteValueNodeModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        data={data}
        childNodes={childNodes}
      />
    </div>
  );
};

export const RootNode = ({ data }) => {
  const {
    setCurrentNode,
    vdbFilesCount,
    loadingVdbFilesCount,
    isConfigRefreshing,
  } = useContext(BaseContext);
  const { setSidePanelOpen, selectedNodeData } = useContext(GraphContext);

  const nodeIsSelected =
    selectedNodeData !== null && selectedNodeData?.id === data.id;
  const showControls = !data.hideControls;

  const handleAddRootChild = () => {
    setCurrentNode(data);
    setSidePanelOpen(true);
    data.onAddNode(data, "root");
  };

  const [{ isOver }, drop] = useDrop({
    accept: "TAG",
    drop: (item, monitor) => {
      const tag = item.tag;
      if (tag) {
        data.onAddNode(data, "root");
        data.handleAddNodeApplied(data, tag);
      }
      return { dropped: true };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const totalFiles = vdbFilesCount?.total_files || 0;

  return (
    <div
      ref={drop}
      className={`flex flex-col items-center ${isOver ? "bg-gray-100 rounded-lg p-2" : ""}`}
    >
      <div className="rounded-xl border-3 border-primary bg-primary bg-opacity-10 border border-primary/90 px-8 py-3 shadow-lg transform hover:scale-105 transition-all">
        {loadingVdbFilesCount || isConfigRefreshing ? (
          <div className="flex items-center justify-center gap-2">
            <div className="w-4 h-4 border-2 border-primary border-t-transparent rounded-full animate-spin" />
            <span>Loading...</span>
          </div>
        ) : (
          <>
            <div className="text-2xl font-bold text-primary text-center">
              {totalFiles.toLocaleString()}
            </div>
            <span className="text-base text-primary/80 font-semibold block text-center">
              Total Files
            </span>
          </>
        )}
      </div>
      <div className="flex flex-row gap-2 mt-1">
        {showControls && (
          <button
            className="hover:scale-110 transition-all flex items-center gap-2 bg-primary text-white rounded-full shadow-lg px-4 py-2"
            onClick={handleAddRootChild}
            title={`Add new node to "${data.label}" tag`}
          >
            {nodeIsSelected ? (
              <>
                <AiFillPlusCircle size={18} />
                <span className="text-sm font-medium">Add Metadata</span>
              </>
            ) : (
              <>
                <AiOutlinePlusCircle size={18} />
                <span className="text-sm font-medium">Add Metadata</span>
              </>
            )}
          </button>
        )}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={false}
        style={{ opacity: 0 }}
      />
    </div>
  );
};

export const nodeTypes = {
  question: TagNode,
  value: ValueNode,
  root: RootNode,
};
