import { Tooltip } from "@mui/material";

export const TagScore = ({ tagScore }) => {
  if (!tagScore) return null;
  return (
    <div className="flex items-center mt-1">
      <Tooltip title="Tag confidence score based on the average confidence values for this tag">
        <div className="text-xs flex items-center">
          <span
            className={`px-1.5 py-0.5 rounded-md font-medium ${
              tagScore >= 0.9
                ? "bg-green-100 text-green-800"
                : tagScore >= 0.8
                  ? "bg-blue-100 text-blue-800"
                  : "bg-amber-100 text-amber-800"
            }`}
          >
            {tagScore.toFixed(2)}
          </span>
        </div>
      </Tooltip>
    </div>
  );
};
