import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import {
  classificationService,
  progressTrackingService,
} from "../../../../services/api";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/react";
import { getNewConditionsFromListOfStringConditions } from "../../Workflows/screens/DataDiscovery/GraphUtils";

export const useJobProgress = ({
  jobId,
  isRunning,
  onComplete,
  onError,
  onProgress = (responseData) => {},
  pollInterval = 4000,
  apiKey,
  successMessage = null,
}) => {
  const [progress, setProgress] = useState(0.0);
  const [additionalStats, setAdditionalStats] = useState({});

  useEffect(() => {
    const trackJobProgress = async () => {
      try {
        if (!jobId || !isRunning) return;

        const response = await progressTrackingService.trackJob(jobId, apiKey);

        // Check if job failed
        if (response.data.status === "failed") {
          setProgress(0.0);
          setAdditionalStats({});
          if (onError) {
            onError({
              message: "Job failed",
              response: {
                data: {
                  detail: "Job failed. See progress tracking for details.",
                },
              },
            });
          }
          return;
        }

        // Calculate progress as a number between 0 and 1
        const currentProgress = Number(
          (
            response.data.completed_progress_increments /
            response.data.total_progress_increments
          ).toFixed(4),
        );

        // Only update if there's actual progress
        if (currentProgress > progress) {
          setProgress(currentProgress);
          // Store any additional stats from the response
          setAdditionalStats(response.data);
          onProgress(response.data);
        }

        // Check if job is complete
        if (response.data.status !== "in_progress") {
          // Reset states
          setProgress(0.0);
          setAdditionalStats({});

          // Call completion callback
          if (onComplete) {
            onComplete(response.data);
          }
        }
      } catch (error) {
        console.error("Job tracking error:", error);

        // Ignore job not found error for large datasets
        const isJobNotFoundError =
          error.response?.data?.detail === "Job ID not found" ||
          error.response?.status === 404;

        if (isJobNotFoundError) {
          console.log("Job not found yet, will retry on next poll interval");
          return; // Exit without resetting progress or calling onError
        }

        // Reset states
        setProgress(0.0);
        setAdditionalStats({});

        // Call error callback
        if (onError) {
          onError(error);
        }
      }
    };

    // Only set up polling if we have an active job
    if (jobId && isRunning) {
      // Initial check
      trackJobProgress();
      // Set up polling interval
      const pollTimer = setInterval(trackJobProgress, pollInterval);
      return () => clearInterval(pollTimer);
    }
  }, [
    jobId,
    isRunning,
    progress,
    apiKey,
    onComplete,
    onError,
    onProgress,
    pollInterval,
    successMessage,
  ]);

  return {
    progress,
    additionalStats,
  };
};

export const classifyAll = async (
  vdbmLastActive,
  llmLastActive,
  deasyApiKey,
  selectedNodeData,
  setJobId,
  isClassifying,
  setIsClassifying,
  vdbFilesCount,
  tags = null,
  hierarchy = null,
  useConditions = true,
  overwrite = false,
) => {
  if (isClassifying) return;
  setIsClassifying(true);
  const jobID = uuidv4();
  let wrappedConditions;

  if (useConditions) {
    wrappedConditions = getNewConditionsFromListOfStringConditions(
      selectedNodeData?.nodePath || [],
    );
  }
  try {
    classificationService.classifyAll(
      vdbmLastActive, // vdbmLastActive
      llmLastActive, // llmLastActive
      tags, // tags
      deasyApiKey, // deasyApiKey
      jobID, // jobID
      null, // dataSliceId
      vdbFilesCount?.total_files, // totalDataSets
      hierarchy, // hierarchy
      wrappedConditions, // conditions
      overwrite, // overwrite
    );
    toast.success("Started preparing datasets");
    setJobId(jobID);
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    toast.error("Failed to start preparing datasets");
    setIsClassifying(false);
  }
};

export const classify = async (
  vdbmLastActive,
  llmLastActive,
  fileNames,
  deasyApiKey,
  setJobId,
  isClassifying,
  setIsClassifying,
  tags = null,
  currentTree = {},
  soft_run = false,
  overwrite = false,
  dataSliceId = null,
  blockingCall = false,
) => {
  if (isClassifying) return;
  setIsClassifying(true);
  const jobID = uuidv4();

  try {
    classificationService.classify(
      vdbmLastActive,
      llmLastActive,
      fileNames,
      tags,
      deasyApiKey,
      soft_run,
      overwrite,
      currentTree,
      jobID,
      dataSliceId,
      blockingCall,
    );

    toast.success("Started preparing selected datasets");
    setJobId(jobID);
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    toast.error("Failed to prepare selected datasets");
    setIsClassifying(false);
  }
};
