import { FiPlus, FiMinus, FiSearch, FiX } from "react-icons/fi";
import {
  DatabaseZap,
  BetweenVerticalStart,
  RotateCcw,
  Layers,
} from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { useState, useContext } from "react";
import { BaseContext } from "../../../../../../../contexts/BaseContext";
import { useJobProgress } from "../../../../../DeasyConfig/ConfigElements/useJobProgress";
import { toast } from "react-hot-toast";
import { classificationService } from "../../../../../../../services/api";
import { GraphContext } from "../../../../../../../contexts/GraphContext";
import { FilePickerModal } from "../../../../../../utils/FilePickerModal";
import { Modal } from "@mui/material";
import * as Sentry from "@sentry/react";

export const TagPanel = ({
  // Selection state
  selectedClassifyFiles,
  selectedNodesFileCount,

  // Tag state
  excludedTags,

  // Loading states
  isLoadingFileCount,

  // Actions
  setSelectedClassifyFiles,
  setShowCreateDataSlice,
  setExcludedTags,

  // Helper functions
  getNewConditionsFromNodes,
}) => {
  // Context data
  const { savedTags, vdbFilesCount, deasyUserConfig } = useContext(BaseContext);

  const { discoveryGraphData, selectedNodes, setSelectedNodes } =
    useContext(GraphContext);

  // Deasy user config
  const {
    vdbmConfig: { LastActive: vdbmLastActive },
    llmConfig: { LastActive: llmLastActive },
    deasyApiKey,
  } = deasyUserConfig;

  // States
  const [isClassifying, setIsClassifying] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);
  const [nodesForClassify, setNodesForClassify] = useState([]);
  const [tagSearchTerm, setTagSearchTerm] = useState(""); // Search term for filtering tags in the UI only
  const [isContextualizing, setIsContextualizing] = useState(false);
  const [contextualizeJobId, setContextualizeJobId] = useState(null);

  // Tag Helper functions
  const getAllTagsFromGraph = (data) => {
    const tags = new Set();
    const traverse = (obj) => {
      if (!obj || typeof obj !== "object") return;
      Object.entries(obj).forEach(([key, value]) => {
        // Count any tag that has TagAvailableValues, regardless of whether it has child values
        if (key !== "TagAvailableValues" && value?.TagAvailableValues) {
          tags.add(key);
        }
        if (typeof value === "object" && value !== null) {
          traverse(value);
        }
      });
    };
    traverse(data);
    return tags;
  };

  const getTagsFromSelectedNodes = (nodes, graphData) => {
    if (!nodes.length) {
      return getAllTagsFromGraph(graphData);
    }

    const tags = new Set();
    nodes.forEach((node) => {
      let currentData = graphData;
      // Navigate to the selected node's data
      for (const pathItem of node.data.nodePath) {
        if (!currentData[pathItem]) break;
        currentData = currentData[pathItem];
      }
      // Get tags from this node's subtree
      const nodeTags = getAllTagsFromGraph(currentData);
      nodeTags.forEach((tag) => tags.add(tag));
    });
    return tags;
  };

  // Function to check if extraction is disabled
  const getDisabledReason = () => {
    // First check if there are any tags in the graph at all
    const hasAnyTags = Object.entries(discoveryGraphData).some(
      ([key, value]) =>
        key !== "TagAvailableValues" && value?.TagAvailableValues,
    );

    if (!hasAnyTags) {
      return "No tags available in the graph to extract";
    }

    // If "All Files" is selected, only check for tags
    if (selectedClassifyFiles === null) {
      return null;
    }

    // For other cases, check if we have any files selected
    const hasFilesSelected =
      (Array.isArray(selectedClassifyFiles) &&
        selectedClassifyFiles.length > 0) || // Specific files selected
      selectedNodesFileCount > 0; // Files from node selection

    if (!hasFilesSelected) {
      return "Select nodes or files to extract";
    }

    return null;
  };

  const getChildTagsForTag = (data, targetTag) => {
    const childTags = new Set();

    const traverse = (obj) => {
      if (!obj || typeof obj !== "object") return;

      Object.entries(obj).forEach(([key, value]) => {
        if (key === targetTag) {
          // Once we find our target tag, collect all child tags within its subtree
          getAllChildTags(value).forEach(({ tagName }) => {
            childTags.add(tagName);
          });
        } else if (typeof value === "object" && value !== null) {
          traverse(value);
        }
      });
    };

    traverse(data);
    return childTags;
  };

  const handleTagExclusion = (tagName, e) => {
    e.stopPropagation();
    setExcludedTags((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(tagName)) {
        // When including a tag back, just remove it from excluded set
        newSet.delete(tagName);
      } else {
        // When excluding a tag, also exclude all its children
        newSet.add(tagName);
        const childTags = getChildTagsForTag(discoveryGraphData, tagName);
        childTags.forEach((tag) => newSet.add(tag));
      }
      return newSet;
    });
  };

  const buildSubsetOfGraph = (excludedTags, graphData) => {
    const traverse = (sourceNode, targetNode) => {
      if (!sourceNode || typeof sourceNode !== "object") return;

      Object.entries(sourceNode).forEach(([key, value]) => {
        if (excludedTags.has(key)) return;

        if (typeof value === "object" && value !== null) {
          // Only create a new node if parent isn't excluded
          targetNode[key] = {};
          traverse(value, targetNode[key]);
        } else {
          // Add leaf node values directly
          targetNode[key] = value;
        }
      });
    };

    const subset = {};
    traverse(graphData, subset);
    return subset;
  };

  const buildSubsetOfGraphFromIncluded = (includedTags, graphData) => {
    const tagRelationships = {};

    const mapRelationships = (node, path = []) => {
      if (!node || typeof node !== "object") return;

      Object.entries(node).forEach(([key, value]) => {
        if (includedTags.includes(key)) {
          tagRelationships[key] = {
            parentPath: [...path],
            data: value,
          };
        }

        if (typeof value === "object" && value !== null) {
          mapRelationships(value, [...path, key]);
        }
      });
    };

    mapRelationships(graphData);

    const nestedTags = new Set();

    for (const tag of includedTags) {
      const tagInfo = tagRelationships[tag];
      if (tagInfo) {
        for (const parent of tagInfo.parentPath) {
          if (includedTags.includes(parent)) {
            nestedTags.add(tag);
            break;
          }
        }
      }
    }

    const result = {};

    for (const tag of includedTags) {
      if (!nestedTags.has(tag) && tagRelationships[tag]) {
        result[tag] = JSON.parse(JSON.stringify(tagRelationships[tag].data));
      }
    }

    return result;
  };

  const getAllChildTags = (data) => {
    const tags = new Map();

    const traverse = (obj) => {
      if (!obj || typeof obj !== "object") return;

      Object.entries(obj).forEach(([key, value]) => {
        if (key !== "TagAvailableValues" && value?.TagAvailableValues) {
          tags.set(key, value);
        }
        if (typeof value === "object" && value !== null) {
          traverse(value);
        }
      });
    };

    traverse(data);
    return Array.from(tags.entries()).map(([tagName, tagData]) => ({
      tagName,
      tagData,
    }));
  };

  // Classify Functions
  const isAllFilesSelected = (files) => files === null;

  const classify = async () => {
    setNodesForClassify([...selectedNodes]);
    const hasNodeSelection = selectedNodes.length > 0;

    const isRootSelection =
      selectedNodes.length === 0 && selectedClassifyFiles?.length === 0;

    const numberOfFilesToClassify = isRootSelection
      ? vdbFilesCount?.total_files || 0
      : isAllFilesSelected(selectedClassifyFiles)
        ? vdbFilesCount?.total_files || 0
        : selectedClassifyFiles?.length > 0
          ? selectedClassifyFiles.length
          : selectedNodesFileCount;

    if (numberOfFilesToClassify === 0) return;

    // Get available tags from the graph
    const availableTags = getTagsFromSelectedNodes(
      selectedNodes,
      discoveryGraphData,
    );

    // Store all available tags, not just the non-excluded ones
    const allTags = Array.from(availableTags);

    // Count how many tags are currently selected (not excluded)
    const selectedTagCount = allTags.filter(
      (tag) => !excludedTags.has(tag),
    ).length;

    setConfirmationDetails({
      tagCount: selectedTagCount,
      tags: allTags, // Store all available tags
      fileCount: numberOfFilesToClassify,
      isRootSelection,
      hasNodeSelection,
    });
    setShowConfirmDialog(true);
  };

  const handleConfirmedClassify = async () => {
    setShowConfirmDialog(false);
    setTagSearchTerm("");
    if (!confirmationDetails) return;

    // Filter out excluded tags for processing
    const selectedTags = confirmationDetails.tags.filter(
      (tag) => !excludedTags.has(tag),
    );

    // If no tags are selected, don't proceed
    if (selectedTags.length === 0) {
      toast.error("No tags selected for extraction");
      return;
    }

    const useNodeBasedClassification =
      confirmationDetails.hasNodeSelection ||
      confirmationDetails.isRootSelection ||
      selectedClassifyFiles === null;

    let graph;
    if (useNodeBasedClassification) {
      const graph_included_tags = buildSubsetOfGraphFromIncluded(
        selectedTags,
        discoveryGraphData,
      );
      graph = buildSubsetOfGraph(excludedTags, graph_included_tags);
    } else {
      graph = buildSubsetOfGraph(excludedTags, discoveryGraphData);
    }
    setExcludedTags(new Set());

    const tagsInGraph = savedTags.reduce((filteredObj, tag) => {
      if (selectedTags.includes(tag.name)) {
        filteredObj[tag.name] = tag;
      }
      return filteredObj;
    }, {});

    let tagCount = 0;
    Object.keys(tagsInGraph).forEach((tag) => {
      if (!excludedTags.has(tag)) {
        tagCount++;
      }
    });

    if (selectedTags.length === 0) {
      console.log("No tags identified in the graph");
      return;
    }

    if (tagCount === 0) {
      toast.error("No tags selected for extraction");
      return;
    }

    setIsClassifying(true);
    const jobID = uuidv4();
    setJobId(jobID); // Always set job ID

    const pendingJob = {
      tracker_id: jobID,
      task_type: "classify",
      status: "pending",
      start_time: new Date().toISOString(),
      progress: 0,
      total_files: confirmationDetails.fileCount,
      processed_files: 0,
      file_details: {
        total: confirmationDetails.fileCount,
        processed: 0,
        successful: 0,
        failed: 0,
      },
      additional_stats: {
        tags_created: 0,
        tags_selected: selectedTags.length,
      },
      is_pending: true,
    };

    const existingPendingJobs = JSON.parse(
      localStorage.getItem("pendingProgressJobs") || "[]",
    );
    const updatedPendingJobs = [...existingPendingJobs, pendingJob];
    localStorage.setItem(
      "pendingProgressJobs",
      JSON.stringify(updatedPendingJobs),
    );

    if (useNodeBasedClassification) {
      const newConditions = confirmationDetails.isRootSelection
        ? null
        : getNewConditionsFromNodes(nodesForClassify);

      classificationService.classifyAll(
        vdbmLastActive, // vdbmLastActive
        llmLastActive, // llmLastActive
        tagsInGraph, // tags
        deasyApiKey, // deasyApiKey
        jobID, // jobID
        null, // dataSliceId
        confirmationDetails.fileCount, // totalDataSets
        graph, // hierarchy
        newConditions, // conditions
        false, // overwrite
      );
    } else if (selectedClassifyFiles?.length > 0) {
      classificationService
        .classify(
          vdbmLastActive,
          llmLastActive,
          selectedClassifyFiles,
          tagsInGraph,
          deasyApiKey,
          false,
          false,
          graph,
          jobID,
          null,
          false,
        )
        .catch((error) => {
          setIsClassifying(false);
          console.error(error);
          Sentry.captureException(error);
          toast.error(error.response.data.detail);
          removePendingJob(jobID);
        });
    }
  };

  const removePendingJob = (jobId) => {
    const existingPendingJobs = JSON.parse(
      localStorage.getItem("pendingProgressJobs") || "[]",
    );
    const updatedPendingJobs = existingPendingJobs.filter(
      (job) => job.tracker_id !== jobId,
    );
    localStorage.setItem(
      "pendingProgressJobs",
      JSON.stringify(updatedPendingJobs),
    );
  };

  const { progress: classifyProgress, additionalStats } = useJobProgress({
    jobId,
    isRunning: isClassifying,
    onComplete: (responseData) => {
      setIsClassifying(false);
      setJobId(null);
      setExcludedTags(new Set());
      removePendingJob(jobId);
    },
    onError: (error) => {
      setIsClassifying(false);
      setJobId(null);
      setExcludedTags(new Set());
      removePendingJob(jobId);
    },
    pollInterval: 7000,
    apiKey: deasyApiKey,
    successMessage: null,
  });

  const getFileCount = () => {
    return selectedNodesFileCount > 0
      ? selectedNodesFileCount
      : selectedClassifyFiles === null // All Files selected
        ? vdbFilesCount?.total_files || 0
        : Array.isArray(selectedClassifyFiles) &&
            selectedClassifyFiles.length > 0
          ? selectedClassifyFiles.length
          : 0;
  };

  const [filePickerOpen, setFilePickerOpen] = useState(false);

  const handleContextualize = async () => {
    // Show confirmation dialog
    if (window.confirm(`Are you sure you want to contextualize your data?`)) {
      setIsContextualizing(true);
      const jobID = uuidv4();
      setContextualizeJobId(jobID); // Store job ID for tracking

      try {
        classificationService.contextualizeAll(
          vdbmLastActive, // vdbmLastActive
          llmLastActive, // llmLastActive
          deasyApiKey, // deasyApiKey
          jobID, // jobID
          null, // dataSliceId
          false, // overwrite
        );

        toast.success("Contextualization started");
      } catch (error) {
        setIsContextualizing(false);
        setContextualizeJobId(null);
        console.error(error);
        Sentry.captureException(error);
        toast.error("Failed to start contextualization");
      }
    }
  };

  // Use the same useJobProgress hook for contextualization
  const {
    progress: contextualizeProgress,
    additionalStats: contextualizeStats,
  } = useJobProgress({
    jobId: contextualizeJobId,
    isRunning: isContextualizing,
    onComplete: (responseData) => {
      setIsContextualizing(false);
      setContextualizeJobId(null);
      toast.success("Contextualization completed successfully");
    },
    onError: (error) => {
      setIsContextualizing(false);
      setContextualizeJobId(null);
      toast.error("Contextualization failed");
    },
    pollInterval: 7000,
    apiKey: deasyApiKey,
    successMessage: "Contextualization completed successfully",
  });

  return (
    <div className="flex flex-col gap-2">
      {/* Extract Section */}

      {selectedNodes.length === 0 && (
        <button
          onClick={() => setFilePickerOpen(true)}
          className="w-full px-3 py-2 rounded-md text-sm
              bg-white/10 text-white hover:bg-white/20
              flex items-center justify-between transition-all duration-200"
        >
          <span>
            {selectedClassifyFiles === null
              ? "All Files"
              : Array.isArray(selectedClassifyFiles) &&
                  selectedClassifyFiles.length > 0
                ? `${selectedClassifyFiles.length} Files`
                : "Select Files"}
          </span>
          {/* Show reset button if any files are selected */}
          {(selectedClassifyFiles === null ||
            (Array.isArray(selectedClassifyFiles) &&
              selectedClassifyFiles.length > 0)) && (
            <RotateCcw
              size={14}
              className="text-white/70 hover:text-white"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedClassifyFiles([]); // Reset to empty array
                setSelectedNodes([]); // Clear selected nodes
              }}
            />
          )}
        </button>
      )}
      <div className="border-t border-white/10 my-2"></div>

      <div className="space-y-2">
        {/* Extract Button */}
        {(() => {
          const disabledReason = getDisabledReason();
          const numberOfFilesToClassify = getFileCount();

          const availableTags = getTagsFromSelectedNodes(
            selectedNodes,
            discoveryGraphData,
          );

          // Only disable if classifying, if there are no tags, or if there are no available tags
          const isDisabled =
            isClassifying || !!disabledReason || availableTags.size === 0;

          return (
            <div className="space-y-2">
              <button
                onClick={classify}
                disabled={isDisabled}
                title={
                  availableTags.size === 0
                    ? "No tags available to extract"
                    : disabledReason || ""
                }
                className={`
                  w-full px-3 py-2 rounded-md
                  text-sm font-medium
                  flex items-center gap-2
                  transition-all duration-200
                  ${
                    isDisabled
                      ? "bg-gray-600/20 text-white/30 cursor-not-allowed border border-white/5"
                      : "bg-primary hover:bg-primary/90 text-white shadow-lg hover:shadow-primary/20"
                  }
                `}
              >
                <DatabaseZap
                  size={16}
                  className={isDisabled ? "opacity-30" : ""}
                />
                <span>
                  {isClassifying ? "Extracting..." : "Extract"}
                  {isDisabled &&
                    (availableTags.size === 0 ? (
                      <span className="ml-1 text-xs opacity-75">
                        (No tags available to extract)
                      </span>
                    ) : (
                      disabledReason && (
                        <span className="ml-1 text-xs opacity-75">
                          ({disabledReason})
                        </span>
                      )
                    ))}
                </span>
                {numberOfFilesToClassify > 0 && !isDisabled && (
                  <span className="bg-white/20 px-2 py-0.5 rounded-full text-xs ml-auto">
                    {numberOfFilesToClassify.toLocaleString()}
                  </span>
                )}
              </button>

              {/* Progress Bar */}
              {isClassifying && (
                <div className="space-y-1.5">
                  <div className="w-full bg-white/10 rounded-full h-1.5 overflow-hidden">
                    <div
                      className="bg-primary h-full transition-all duration-300 ease-in-out"
                      style={{ width: `${classifyProgress * 100}%` }}
                    />
                  </div>
                  <div className="flex items-center justify-between text-xs text-white/70">
                    <span>{Math.round(classifyProgress * 100)}% Complete</span>
                    {additionalStats?.tags_created > 0 && (
                      <span>{additionalStats.tags_created} Tags Created</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })()}
      </div>

      {/* Data Slice Section */}
      <button
        onClick={() => !isLoadingFileCount && setShowCreateDataSlice(true)}
        disabled={
          isLoadingFileCount ||
          (selectedNodesFileCount === 0 && selectedClassifyFiles !== null) // Enable if All Files selected
        }
        className={`
            w-full px-3 py-2 rounded-md
            text-sm flex items-center gap-2
            transition-all duration-200
            ${
              isLoadingFileCount ||
              (selectedNodesFileCount === 0 && selectedClassifyFiles !== null)
                ? "bg-white/10 text-white/50 cursor-not-allowed"
                : "bg-white/10 text-white hover:bg-white/20"
            }
          `}
      >
        <BetweenVerticalStart size={16} />
        <span>Create Data Slice</span>
        {(selectedNodesFileCount > 0 || selectedClassifyFiles === null) && (
          <span className="bg-white/20 px-2 py-0.5 rounded-full text-xs ml-auto">
            {getFileCount().toLocaleString()}
          </span>
        )}
      </button>

      {/* Contextualize Button */}
      <button
        onClick={() => handleContextualize()}
        disabled={isContextualizing}
        className={`
            w-full px-3 py-2 rounded-md
            text-sm flex items-center gap-2
            transition-all duration-200
            ${
              isContextualizing
                ? "bg-white/10 text-white/50 cursor-not-allowed"
                : "bg-white/10 text-white hover:bg-white/20"
            }
          `}
      >
        <Layers size={16} />
        <span>
          {isContextualizing ? "Contextualizing..." : "Contextualize (Preview)"}
        </span>
      </button>

      {isContextualizing && (
        <div className="space-y-1.5 mt-2">
          <div className="w-full bg-white/10 rounded-full h-1.5 overflow-hidden">
            <div
              className="bg-primary h-full transition-all duration-300 ease-in-out"
              style={{ width: `${contextualizeProgress * 100}%` }}
            />
          </div>
          <div className="flex items-center justify-between text-xs text-white/70">
            <span>{Math.round(contextualizeProgress * 100)}% Complete</span>
            {contextualizeStats?.tags_created > 0 && (
              <span>{contextualizeStats.tags_created} Tags Created</span>
            )}
          </div>
        </div>
      )}

      <FilePickerModal
        openFilePicker={filePickerOpen}
        setOpenFilePicker={setFilePickerOpen}
        selectedFiles={
          Array.isArray(selectedClassifyFiles) ? selectedClassifyFiles : []
        }
        setSelectedFiles={(files) => {
          setSelectedClassifyFiles(files);
        }}
        selectAllForEntireDB={true}
      />

      {/* Add Modal */}
      <Modal
        open={showConfirmDialog}
        onClose={() => {
          setShowConfirmDialog(false);
          setTagSearchTerm("");
        }}
        aria-labelledby="confirm-extract-title"
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl p-6 w-[600px] max-h-[80vh] overflow-y-auto">
          <h2 className="text-xl font-semibold mb-4" id="confirm-extract-title">
            Confirm Metadata Extraction
          </h2>
          <div className="space-y-4">
            <div className="flex items-center justify-between border-b pb-4">
              <p className="text-gray-600">
                Processing {confirmationDetails?.fileCount.toLocaleString()}{" "}
                file{confirmationDetails?.fileCount === 1 ? "" : "s"}
                {confirmationDetails?.isRootSelection
                  ? " from the entire dataset"
                  : ""}
              </p>
              <div className="flex items-center gap-2">
                <span className="text-xs text-gray-500">
                  {
                    confirmationDetails?.tags.filter(
                      (tag) => !excludedTags.has(tag),
                    ).length
                  }{" "}
                  of {confirmationDetails?.tags.length} tags selected
                </span>
                <button
                  onClick={() => {
                    // Toggle all tags
                    const allTags = confirmationDetails?.tags || [];
                    // If search is active, only toggle visible tags
                    const tagsToToggle = tagSearchTerm
                      ? allTags.filter((tag) =>
                          tag
                            .toLowerCase()
                            .includes(tagSearchTerm.toLowerCase()),
                        )
                      : allTags;

                    setExcludedTags((prev) => {
                      const newSet = new Set(prev);
                      // If all visible tags are currently excluded, include all visible tags
                      if (tagsToToggle.every((tag) => newSet.has(tag))) {
                        tagsToToggle.forEach((tag) => newSet.delete(tag));
                      } else {
                        // Otherwise, exclude all visible tags
                        tagsToToggle.forEach((tag) => newSet.add(tag));
                      }
                      return newSet;
                    });
                  }}
                  className="p-1 hover:bg-gray-100 rounded-full text-gray-400 hover:text-primary transition-colors"
                  title={
                    tagSearchTerm ? "Toggle visible tags" : "Toggle all tags"
                  }
                >
                  {(
                    tagSearchTerm
                      ? confirmationDetails?.tags
                          .filter((tag) =>
                            tag
                              .toLowerCase()
                              .includes(tagSearchTerm.toLowerCase()),
                          )
                          .every((tag) => excludedTags.has(tag))
                      : confirmationDetails?.tags.every((tag) =>
                          excludedTags.has(tag),
                        )
                  ) ? (
                    <FiPlus size={16} />
                  ) : (
                    <FiMinus size={16} />
                  )}
                </button>
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <div className="text-sm font-medium text-gray-700">
                  Selected Tags for Extraction
                </div>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search tags..."
                    className="text-sm border border-gray-300 rounded-lg px-3 py-1.5 pl-8 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
                    value={tagSearchTerm}
                    onChange={(e) => setTagSearchTerm(e.target.value)}
                  />
                  <FiSearch
                    className="absolute left-2.5 top-1/2 -translate-y-1/2 text-gray-400"
                    size={14}
                  />
                  {tagSearchTerm && (
                    <button
                      onClick={() => setTagSearchTerm("")}
                      className="absolute right-2.5 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                    >
                      <FiX size={14} />
                    </button>
                  )}
                </div>
              </div>
              {tagSearchTerm && confirmationDetails?.tags && (
                <div className="text-xs text-gray-500 flex justify-between">
                  <span>
                    Showing{" "}
                    {
                      confirmationDetails.tags.filter((tag) =>
                        tag.toLowerCase().includes(tagSearchTerm.toLowerCase()),
                      ).length
                    }{" "}
                    of {confirmationDetails.tags.length} tags
                  </span>
                  <span>
                    {
                      confirmationDetails.tags.filter(
                        (tag) =>
                          !excludedTags.has(tag) &&
                          tag
                            .toLowerCase()
                            .includes(tagSearchTerm.toLowerCase()),
                      ).length
                    }{" "}
                    selected in current view
                  </span>
                </div>
              )}
              <div className="max-h-[40vh] overflow-y-auto space-y-2 bg-gray-50 rounded-lg p-4">
                {confirmationDetails?.tags
                  .filter(
                    (tagName) =>
                      !tagSearchTerm ||
                      tagName
                        .toLowerCase()
                        .includes(tagSearchTerm.toLowerCase()),
                  )
                  .map((tagName) => {
                    const tagData = savedTags.find((t) => t.name === tagName);
                    return (
                      <div key={tagName} className="flex flex-col">
                        <div
                          className={`
                          flex items-center justify-between
                          px-4 py-3 bg-white rounded-lg
                          hover:bg-gray-50 transition-all duration-200
                          ${excludedTags.has(tagName) ? "opacity-40" : ""}
                        `}
                        >
                          <div className="flex items-center gap-2">
                            <span className="text-sm text-gray-700">
                              {tagName}
                            </span>
                            {tagData?.available_values?.length > 0 && (
                              <span className="text-xs text-gray-500 bg-gray-100 px-2 py-0.5 rounded-full">
                                {tagData.available_values.length} values
                              </span>
                            )}
                          </div>

                          <button
                            onClick={(e) => handleTagExclusion(tagName, e)}
                            className={`p-1 hover:bg-gray-100 rounded-full ${
                              excludedTags.has(tagName)
                                ? "text-red-500 bg-red-50"
                                : "text-gray-400 hover:text-red-500"
                            }`}
                            title={
                              excludedTags.has(tagName)
                                ? "Include tag"
                                : "Exclude tag"
                            }
                          >
                            {excludedTags.has(tagName) ? (
                              <FiPlus size={16} />
                            ) : (
                              <FiMinus size={16} />
                            )}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                {confirmationDetails?.tags &&
                  tagSearchTerm &&
                  !confirmationDetails.tags.some((tag) =>
                    tag.toLowerCase().includes(tagSearchTerm.toLowerCase()),
                  ) && (
                    <div className="flex flex-col items-center justify-center py-6 text-gray-500">
                      <p>No tags match your search</p>
                    </div>
                  )}
              </div>
            </div>

            <p className="text-sm text-gray-500 mt-4">
              This process may take some time depending on the size of your
              dataset.
            </p>
          </div>

          <div className="flex justify-end gap-3 mt-6 pt-4 border-t">
            <button
              onClick={() => {
                setShowConfirmDialog(false);
                setTagSearchTerm("");
              }}
              className="px-4 py-2 rounded-lg border border-gray-300 text-gray-700 font-semibold hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleConfirmedClassify();
              }}
              className="px-4 py-2 rounded-lg bg-primary text-white font-semibold hover:bg-emerald-700"
              disabled={confirmationDetails?.tags.every((tag) =>
                excludedTags.has(tag),
              )}
            >
              {confirmationDetails?.tags.every((tag) => excludedTags.has(tag))
                ? "No Tags Selected"
                : `Extract Metadata (${confirmationDetails?.tags.filter((tag) => !excludedTags.has(tag)).length} tags)`}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
