import React, { useRef, useState, useEffect } from "react";
import { deasyFileKey } from "../../../../../config/config";
import {
  metadataService,
  sourceMetadataService,
} from "../../../../../services/api";

export const TABLE_COLUMN_WIDTHS = {
  [deasyFileKey]: 300,
  default: 200,
};

export const processTagValue = (value) => {
  if (value === null || value === undefined) {
    return "Untagged";
  }

  if (typeof value === "string") {
    const cleaned = value.replace(/[[\]"']/g, "").trim();
    return cleaned || "Untagged";
  }

  if (typeof value === "object" && !Array.isArray(value)) {
    if (value.value !== undefined) {
      return processTagValue(value.value);
    }
    return JSON.stringify(value);
  }

  return String(value);
};

export const getHighlightTerms = (pointId, value, evidence) => {
  if (!evidence || !evidence[pointId]) return null;
  return evidence[pointId];
};

export const highlightText = (text, evidence) => {
  if (!text || !evidence) return text || "";

  try {
    // Create position mapping between normalized and original text
    let posMap = [];
    let normalized = "";

    const normalizeChar = (c) => {
      if (/[^\w\s]/.test(c)) return " ";
      return c.toLowerCase();
    };

    // Build normalized string with position mapping
    for (let i = 0; i < text.length; i++) {
      const nc = normalizeChar(text[i]);
      if (nc === " " && normalized.endsWith(" ")) continue;
      normalized += nc;
      posMap.push(i);
    }
    normalized = normalized.trim();
    posMap = posMap.slice(0, normalized.length);

    const cleanEvidence = evidence
      .toLowerCase()
      .replace(/[^\w\s]/g, " ")
      .replace(/\s+/g, " ")
      .trim();

    if (!normalized.includes(cleanEvidence)) {
      // Find longest match using same logic as before
      let maxMatch = "";
      let currentMatch = "";

      for (const char of normalized) {
        const nextPossible = currentMatch + char;
        if (cleanEvidence.includes(nextPossible)) {
          currentMatch = nextPossible;
          if (currentMatch.length > maxMatch.length) {
            maxMatch = currentMatch;
          }
        } else {
          for (let i = currentMatch.length - 1; i >= 0; i--) {
            const shorter = currentMatch.slice(-i);
            if (cleanEvidence.includes(shorter + char)) {
              currentMatch = shorter + char;
              break;
            }
          }
        }
      }

      if (!maxMatch) return text;

      // Find positions using the mapping
      const startNorm = normalized.indexOf(maxMatch);
      const endNorm = startNorm + maxMatch.length;
      const startOrig = posMap[startNorm];
      const endOrig = posMap[endNorm - 1] + 1;

      return [
        text.slice(0, startOrig),
        '<mark class="bg-yellow-200">',
        text.slice(startOrig, endOrig),
        "</mark>",
        text.slice(endOrig),
      ].join("");
    }

    // Handle full evidence match
    const startNorm = normalized.indexOf(cleanEvidence);
    const endNorm = startNorm + cleanEvidence.length;
    const startOrig = posMap[startNorm];
    const endOrig = posMap[endNorm - 1] + 1;

    return [
      text.slice(0, startOrig),
      '<mark class="bg-yellow-200">',
      text.slice(startOrig, endOrig),
      "</mark>",
      text.slice(endOrig),
    ].join("");
  } catch (error) {
    console.error("Error highlighting text:", error);
    return text || "";
  }
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const ExpandableSection = ({
  title,
  icon,
  isExpanded,
  onToggle,
  children,
  description,
  closeOnClickOutside = true,
}) => {
  const contentRef = useRef(null);
  const sectionRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setHeight(Math.min(contentHeight, window.innerHeight * 0.5));
    }
  }, [children]);

  // Add click outside handler for this specific section
  useEffect(() => {
    // Only add listener if section should close on click outside and is expanded
    if (!closeOnClickOutside || !isExpanded) return;

    const handleClickOutside = (event) => {
      if (sectionRef.current && !sectionRef.current.contains(event.target)) {
        requestAnimationFrame(() => {
          onToggle();
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside, {
      capture: true,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, {
        capture: true,
      });
    };
  }, [isExpanded, onToggle, closeOnClickOutside]);

  return (
    <div
      ref={sectionRef}
      className={`w-[20vw] expandable-section flex flex-col rounded-lg ${
        isExpanded
          ? " text-primary shadow-sm border-2 border-primary/20"
          : " bg-gray-50 hover:bg-gray-100 text-gray-700 border-2 border-gray-200 hover:border-primary/30"
      } scrollbar-hide`}
      style={{
        transition: "width 400ms cubic-bezier(0.4, 0, 0.2, 1)",
        msOverflowStyle: "none", // IE and Edge
        scrollbarWidth: "none", // Firefox
        "&::WebkitScrollbar": {
          // Chrome, Safari, Opera
          display: "none",
        },
        position: "relative",
        zIndex: isExpanded ? "40" : "30",
      }}
    >
      <button
        onClick={onToggle}
        className={`w-full px-6 py-2 rounded-lg flex items-center justify-between gap-3
          ${
            isExpanded
              ? "bg-primary/10 text-primary shadow-sm"
              : "bg-gray-50 hover:bg-gray-100 text-gray-700"
          }
          transition-all duration-300 ease-in-out`}
      >
        <div className="flex items-center gap-4">
          <div className={`text-2xl transition-transform duration-300`}>
            {icon}
          </div>
          <div className="flex flex-col items-start">
            <span className="font-semibold text-base">{title}</span>
            <span className="text-xs text-gray-500 mt-0.5">
              {description || "Click to expand"}
            </span>
          </div>
        </div>
        <div
          className={`w-6 h-6 rounded-full flex items-center justify-center
            ${
              isExpanded ? "bg-primary/20" : "bg-gray-200"
            } transition-all duration-300`}
        >
          <span
            className={`transform transition-transform duration-300 ease-out text-sm
              ${isExpanded ? " text-primary" : "text-gray-600"}
            `}
          >
            ▼
          </span>
        </div>
      </button>

      <div
        className="transition-all duration-300 ease-in-out scrollbar-hide"
        style={{
          height: isExpanded ? `${height}px` : "0",
          opacity: isExpanded ? 1 : 0,
          overflow: "hidden",
          visibility: isExpanded ? "visible" : "hidden",
          zIndex: isExpanded ? "40" : "-1",
          transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
          transitionDuration: "400ms",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::WebkitScrollbar": {
            display: "none",
          },
        }}
      >
        <div ref={contentRef} className="rounded-b-lg scrollbar-hide">
          {children}
        </div>
      </div>
    </div>
  );
};

// Add operator symbols for compact display
const OPERATOR_SYMBOLS = {
  any: "",
  all: "",
  ge: "≥",
  gt: ">",
  le: "≤",
  lt: "<",
};

export const ActiveFilters = ({
  filters,
  onRemove,
  conditionals_new,
  setConditionalsNew,
  setRefreshKey,
}) => {
  useEffect(() => {
    if (!conditionals_new && Object.keys(filters).length > 0) {
      // Create default structure with OR between all filters
      const defaultStructure = {
        condition: "OR", // Default to OR between groups
        children: [],
      };
      const defaultConditionGroup = {
        condition: "AND",
        children: [],
      };

      // Add each filter tag as a separate child initially
      Object.entries(filters).forEach(([column, values]) => {
        values.forEach((value) => {
          // Check if the value has an operator
          if (typeof value === "object" && value.value && value.operator) {
            defaultConditionGroup.children.push({
              tag: {
                name: column,
                values: [value.value],
                operator: value.operator,
              },
            });
          } else {
            defaultConditionGroup.children.push({
              tag: {
                name: column,
                values: [value],
              },
            });
          }
        });
        defaultStructure.children.push(defaultConditionGroup);
      });

      setConditionalsNew(defaultStructure);
    } else if (conditionals_new && Object.keys(filters).length > 0) {
      // Check if all filters are included in conditionals_new
      // This ensures all filters always show up in the Rules Builder
      let allFiltersIncluded = true;
      let missingFilters = [];

      // Flatten the conditionals_new structure to find all included tags
      const flattenStructure = (node, flatTags = []) => {
        if (node.tag) {
          // Include operator if available in the key
          const operator = node.tag.operator ? `${node.tag.operator}:` : "";
          flatTags.push(`${node.tag.name}:${operator}${node.tag.values[0]}`);
        }
        if (node.children && Array.isArray(node.children)) {
          node.children.forEach((child) => flattenStructure(child, flatTags));
        }
        return flatTags;
      };

      const includedTags = flattenStructure(conditionals_new);

      // Check if any filter is missing
      Object.entries(filters).forEach(([column, values]) => {
        values.forEach((value) => {
          // For values with operators
          if (typeof value === "object" && value.value && value.operator) {
            const tagKey = `${column}:${value.operator}:${value.value}`;
            if (!includedTags.includes(tagKey)) {
              allFiltersIncluded = false;
              missingFilters.push({
                name: column,
                values: [value.value],
                operator: value.operator,
              });
            }
          } else {
            // For simple string values
            const tagKey = `${column}:${value}`;
            if (!includedTags.includes(tagKey)) {
              allFiltersIncluded = false;
              missingFilters.push({ name: column, values: [value] });
            }
          }
        });
      });

      if (!allFiltersIncluded) {
        setConditionalsNew((prev) => {
          const newStructure = JSON.parse(JSON.stringify(prev));
          const lastGroupIndex = newStructure.children.length - 1;

          missingFilters.forEach((filter) => {
            if (lastGroupIndex >= 0) {
              newStructure.children[lastGroupIndex].children.push({
                tag: filter,
              });
            } else {
              newStructure.children.push({
                condition: "AND",
                children: [{ tag: filter }],
              });
            }
          });
          return newStructure;
        });
      }
    }
  }, [filters, conditionals_new, setConditionalsNew]);

  if (Object.keys(filters).length === 0) return null;

  // Toggle the logic operator (AND/OR) for a specific group
  const toggleLogic = (groupPath = []) => {
    setConditionalsNew((prev) => {
      if (!prev) return null;

      const newStructure = JSON.parse(JSON.stringify(prev)); // Deep clone

      // If it's root level (empty path)
      if (groupPath.length === 0) {
        newStructure.condition =
          newStructure.condition === "AND" ? "OR" : "AND";
        return newStructure;
      }

      // Navigate to the specified group
      let currentNode = newStructure;
      let i = 0;

      // Navigate the path except the last index
      while (i < groupPath.length - 1) {
        currentNode = currentNode.children[groupPath[i]];
        i++;
      }

      // Toggle the condition at the target level
      const targetIndex = groupPath[groupPath.length - 1];
      if (currentNode.children[targetIndex].condition) {
        currentNode.children[targetIndex].condition =
          currentNode.children[targetIndex].condition === "AND" ? "OR" : "AND";
      }

      return newStructure;
    });
    setRefreshKey((prev) => prev + 1);
  };

  // Create a new empty group
  const createEmptyGroup = () => {
    setConditionalsNew((prev) => {
      if (!prev) return null;

      const newStructure = JSON.parse(JSON.stringify(prev));
      newStructure.children.push({
        condition: "AND",
        children: [],
      });

      return newStructure;
    });
  };

  // Function to render a filter chip with operator if available
  const renderFilterChip = (column, value, onRemove) => {
    // Check if value has an operator
    const hasOperator =
      typeof value === "object" && value.value !== undefined && value.operator;
    const displayValue = hasOperator ? value.value : value;
    const operator = hasOperator ? value.operator : null;

    return (
      <div
        key={`${column}-${hasOperator ? `${operator}-${displayValue}` : displayValue}`}
        className="inline-flex items-center mr-2 mb-2 px-3 py-1.5 rounded-full bg-blue-50 border border-blue-100 text-sm"
      >
        <span className="mr-2 font-medium text-blue-700">{column}:</span>
        {operator && (
          <span className="mr-1 text-gray-600">
            {OPERATOR_SYMBOLS[operator]}
          </span>
        )}
        <span className="text-gray-700">{displayValue}</span>
        <button
          onClick={() => onRemove(column, value)}
          className="ml-2 text-gray-400 hover:text-red-500 focus:outline-none"
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 8.586L6.707 5.293a1 1 0 00-1.414 1.414L8.586 10l-3.293 3.293a1 1 0 101.414 1.414L10 11.414l3.293 3.293a1 1 0 001.414-1.414L11.414 10l3.293-3.293a1 1 0 00-1.414-1.414L10 8.586z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    );
  };

  // Display flat filter list if conditionals_new not available
  if (!conditionals_new) {
    return (
      <div className="filter-chips flex flex-wrap">
        {Object.entries(filters).map(([column, values]) =>
          values.map((value) => renderFilterChip(column, value, onRemove)),
        )}
      </div>
    );
  }

  // Otherwise display the rules builder UI
  return (
    <div className="filter-rules space-y-4">
      <div className="flex flex-col">
        <h3 className="text-sm font-semibold text-gray-700 mb-1">
          Filter Logic
        </h3>

        <div className="flex flex-col gap-4 bg-gray-50 p-3 rounded-lg border border-gray-200">
          {/* Top level match condition */}
          <div className="flex items-center">
            <button
              className={`px-3 py-1 rounded text-sm font-medium ${
                conditionals_new.condition === "AND"
                  ? "bg-blue-100 text-blue-800"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => toggleLogic([])}
            >
              ALL groups must match
            </button>
            <span className="mx-2 text-sm text-gray-500">or</span>
            <button
              className={`px-3 py-1 rounded text-sm font-medium ${
                conditionals_new.condition === "OR"
                  ? "bg-blue-100 text-blue-800"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => toggleLogic([])}
            >
              ANY group can match
            </button>
          </div>

          {/* Filter groups */}
          <div className="space-y-4">
            {conditionals_new.children.map((group, groupIndex) => (
              <div
                key={`group-${groupIndex}`}
                className="p-3 bg-white rounded-lg border border-gray-200 shadow-sm"
              >
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center text-sm">
                    <span className="font-medium text-gray-700 mr-3">
                      Group {groupIndex + 1}
                    </span>
                    <button
                      className={`px-2 py-0.5 rounded text-xs font-medium ${
                        group.condition === "AND"
                          ? "bg-blue-100 text-blue-800"
                          : "bg-gray-200 text-gray-800"
                      }`}
                      onClick={() => toggleLogic([groupIndex])}
                    >
                      ALL conditions must match
                    </button>
                    <span className="mx-1 text-xs text-gray-500">or</span>
                    <button
                      className={`px-2 py-0.5 rounded text-xs font-medium ${
                        group.condition === "OR"
                          ? "bg-blue-100 text-blue-800"
                          : "bg-gray-200 text-gray-800"
                      }`}
                      onClick={() => toggleLogic([groupIndex])}
                    >
                      ANY condition can match
                    </button>
                  </div>
                </div>

                <div className="space-y-2">
                  {group.children && group.children.length > 0 ? (
                    <div className="flex flex-wrap -mx-1">
                      {group.children.map(
                        (child, childIndex) =>
                          child.tag && (
                            <div
                              key={`tag-${childIndex}`}
                              className="px-1 py-1"
                            >
                              <div className="flex items-center bg-blue-50 px-3 py-1.5 rounded-full border border-blue-100">
                                <span className="font-medium text-blue-700 text-sm mr-1">
                                  {child.tag.name}:
                                </span>
                                {child.tag.operator && (
                                  <span className="text-gray-600 text-sm mr-1">
                                    {OPERATOR_SYMBOLS[child.tag.operator]}
                                  </span>
                                )}
                                <span className="text-gray-700 text-sm">
                                  {child.tag.values[0]}
                                </span>
                                <button
                                  onClick={() =>
                                    onRemove(
                                      child.tag.name,
                                      child.tag.operator
                                        ? {
                                            value: child.tag.values[0],
                                            operator: child.tag.operator,
                                          }
                                        : child.tag.values[0],
                                    )
                                  }
                                  className="ml-2 text-gray-400 hover:text-red-500 focus:outline-none"
                                >
                                  <svg
                                    className="w-3 h-3 fill-current"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M10 8.586L6.707 5.293a1 1 0 00-1.414 1.414L8.586 10l-3.293 3.293a1 1 0 101.414 1.414L10 11.414l3.293 3.293a1 1 0 001.414-1.414L11.414 10l3.293-3.293a1 1 0 00-1.414-1.414L10 8.586z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          ),
                      )}
                    </div>
                  ) : (
                    <div className="text-sm text-gray-500 italic">
                      No conditions in this group
                    </div>
                  )}
                </div>

                <div className="mt-3 text-right">
                  {group.children && group.children.length > 0 && (
                    <span className="text-xs text-gray-500">
                      {group.children.length} condition
                      {group.children.length !== 1 ? "s" : ""}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Add group button */}
          <button
            onClick={createEmptyGroup}
            className="inline-flex items-center justify-center px-3 py-1 border border-blue-200 text-blue-800 bg-blue-50 rounded-md text-sm hover:bg-blue-100 transition-colors"
          >
            <svg
              className="w-3 h-3 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            Add Filter Group
          </button>
        </div>
      </div>

      {/* Compact view for child groups */}
      <div className="mt-4 space-y-2">
        <div className="text-sm font-medium text-gray-700">Group Summary</div>
        {conditionals_new.children.map((child, index) => (
          <div
            key={`group-summary-${index}`}
            className="bg-gray-50 p-2 rounded-lg border border-gray-200"
          >
            <div className="flex justify-between items-center">
              <div className="font-medium text-xs text-gray-700">
                Group {index + 1}
                <span className="ml-2 text-gray-500">({child.condition})</span>
              </div>
            </div>

            {child.children && child.children.length > 1 && (
              <div className="mb-2 px-2 py-1 bg-white/60 rounded-md border border-gray-200 flex flex-wrap items-center gap-1 text-xs text-gray-600">
                {child.children.slice(0, 2).map(
                  (grandchild, i) =>
                    grandchild &&
                    grandchild.tag && (
                      <React.Fragment key={`logic-${i}`}>
                        {i > 0 && (
                          <span className="font-bold">{child.condition}</span>
                        )}
                        <span className="bg-gray-100 px-1.5 py-0.5 rounded border border-gray-200">
                          {`${grandchild.tag.name}:${
                            grandchild.tag.operator
                              ? OPERATOR_SYMBOLS[grandchild.tag.operator]
                              : ""
                          }${grandchild.tag.values[0]}`}
                        </span>
                      </React.Fragment>
                    ),
                )}
                {child.children.length > 2 && (
                  <span className="font-bold">...</span>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export const fetchChunkEvidence = async ({
  fileName,
  chunkId,
  tagName,
  value,
  chunkData,
  deasyUserConfig,
  setChunkData,
  setError,
  setActiveEvidence,
  setLoadingEvidenceMap,
}) => {
  // Create a unique key for this evidence request
  const evidenceKey = `${fileName}:${chunkId}:${tagName}:${value}`;

  try {
    // If evidence is already loaded, just toggle visibility
    if (chunkData[fileName]?.[chunkId]?.evidence?.[tagName]?.[value]) {
      setActiveEvidence((prev) => {
        if (
          prev?.chunkId === chunkId &&
          prev?.tagName === tagName &&
          prev?.value === value
        ) {
          return null;
        }
        return { chunkId, tagName, value };
      });
      return;
    }

    // Set loading state
    setLoadingEvidenceMap((prev) => ({
      ...prev,
      [evidenceKey]: true,
    }));

    // Show evidence box with loading state
    setActiveEvidence({ chunkId, tagName, value });

    const activeVdbConfig =
      deasyUserConfig.vdbmConfig?.Configs?.[
        deasyUserConfig.vdbmConfig?.LastActive
      ] || {};
    const tagValue = Array.isArray(value) ? value[0] : value;

    const [evidenceResponse, textResponse] = await Promise.all([
      metadataService.getTagEvidence(
        activeVdbConfig?.name,
        fileName,
        tagName,
        tagValue,
        deasyUserConfig.deasyApiKey,
      ),
      sourceMetadataService.listMetadata(
        activeVdbConfig?.name,
        deasyUserConfig.deasyApiKey,
        "node",
        null,
        [fileName],
        [chunkId],
        null,
      ),
    ]);

    if (evidenceResponse?.data?.evidence || textResponse?.metadata) {
      setChunkData((prev) => {
        const newChunkData = { ...prev };
        if (!newChunkData[fileName]) newChunkData[fileName] = {};
        if (!newChunkData[fileName][chunkId])
          newChunkData[fileName][chunkId] = {};

        const chunkObj = newChunkData[fileName][chunkId];

        // Add evidence
        if (!chunkObj.evidence) chunkObj.evidence = {};
        if (!chunkObj.evidence[tagName]) chunkObj.evidence[tagName] = {};
        chunkObj.evidence[tagName][value] = evidenceResponse.data.evidence;

        // Store full text
        const fullText = textResponse?.metadata?.[chunkId]?.text;
        chunkObj.fullText = fullText;

        return newChunkData;
      });
    }
  } catch (error) {
    console.error("Error fetching evidence:", error);
    setError(`Failed to load evidence for chunk ${chunkId}`);
    // Close evidence box on error
    setActiveEvidence(null);
  } finally {
    // Clear loading state
    setLoadingEvidenceMap((prev) => {
      const newMap = { ...prev };
      delete newMap[evidenceKey];
      return newMap;
    });
  }
};

export const handleDataSliceChange = ({
  selected,
  onFiltersChange,
  setExpandedSections,
  setAvailableColumns,
  setSelectedColumns,
  setSelectedDataSlice,
  deasyFileKey,
}) => {
  if (selected?.selectedParentNodes) {
    // Group nodes by their parent tags to handle OR conditions within same tag
    const nodesByTag = selected.selectedParentNodes.reduce((acc, node) => {
      const [tagName, value] = node.data.nodePath;
      if (!acc[tagName]) {
        acc[tagName] = new Set();
      }
      acc[tagName].add(value);
      return acc;
    }, {});

    // Convert to final conditions format
    const conditions = Object.entries(nodesByTag).reduce(
      (acc, [tagName, values]) => {
        acc[tagName] = Array.from(values);
        return acc;
      },
      {},
    );

    // Update filters
    onFiltersChange(conditions);

    // Auto-expand both sections
    setExpandedSections((prev) => ({
      ...prev,
      controls: true,
      filters: true,
    }));

    // Add filtered columns to selected columns
    const columnsToAdd = Object.keys(conditions);
    if (columnsToAdd.length > 0) {
      // First ensure these columns are available
      setAvailableColumns((prev) => {
        const newColumns = new Set([...prev, ...columnsToAdd]);
        return Array.from(newColumns);
      });

      // Then add them to selected columns
      setSelectedColumns((prev) => {
        const newColumns = new Set([...prev, ...columnsToAdd]);
        return Array.from(newColumns);
      });
    }
  } else {
    onFiltersChange({});
  }

  setSelectedDataSlice(selected);
};

export const fetchTagTotals = async ({
  deasyUserConfig,
  dataslice_id = null,
  node_condition = null,
  columns = null,
  setTagStats,
}) => {
  try {
    const response = await metadataService.getTagTotals(
      deasyUserConfig.vdbmConfig?.LastActive,
      deasyUserConfig.deasyApiKey,
      dataslice_id,
      node_condition,
      columns,
    );

    if (response?.data) {
      setTagStats(response.data);
    }
  } catch (error) {
    console.error("Error fetching tag totals:", error);
  }
};

export const extractTagsFromSchema = (graphData) => {
  if (!graphData || Object.keys(graphData).length === 0) {
    return [];
  }

  const tags = new Set();
  const traverseNode = (node) => {
    if (!node || typeof node !== "object") return;

    // If the node has a 'name' property, it's likely a tag node
    if (node.name && typeof node.name === "string") {
      tags.add(node.name);
    }

    // Also check direct properties, as some schemas store tag names as keys
    Object.keys(node).forEach((key) => {
      if (
        key !== "children" &&
        key !== "name" &&
        key !== "file_count" &&
        key !== "percentage"
      ) {
        tags.add(key);
      }

      // Recurse through children
      if (node.children && Array.isArray(node.children)) {
        node.children.forEach((child) => traverseNode(child));
      }

      // Handle nested objects that might be part of the schema structure
      if (typeof node[key] === "object" && node[key] !== null) {
        traverseNode(node[key]);
      }
    });
  };

  traverseNode(graphData);
  return Array.from(tags);
};
