import React, { useCallback, useState, useEffect } from "react";
import {
  TableBody as MuiTableBody,
  TableRow,
  TableCell,
  Fade,
} from "@mui/material";
import ChunkRow from "./ChunkRow";
import CustomTableCell from "./TableCell";
import { metadataService } from "../../../../../../services/api";

const TableBody = ({
  tableData,
  displayColumns,
  deasyFileKey,
  TABLE_COLUMN_WIDTHS,

  chunkState: {
    expandedRow,
    loadingChunks,
    chunkData,
    setExpandedRow,
    setLoadingChunks,
    setChunkData,
  },

  evidenceState: {
    activeFullText,
    activeEvidence,
    showEvidence,
    toggleChunkText,
    handleFetchChunkEvidence,
    setShowEvidence,
    renderEvidenceRow,
    loadingEvidenceMap,
  },

  processTagValue,
  setError,
  deasyUserConfig,
  onTagClick,
}) => {
  const [expandedSections, setExpandedSections] = useState({});

  // Initialize expanded state when a row is expanded
  useEffect(() => {
    if (expandedRow) {
      setExpandedSections((prev) => ({
        ...prev,
        [expandedRow]: {
          relevant: true, // Relevant chunks expanded by default
          irrelevant: false, // Irrelevant chunks collapsed by default
        },
      }));
    }
  }, [expandedRow]);

  const toggleSection = useCallback((fileName, section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [fileName]: {
        ...prev[fileName],
        [section]: !prev[fileName]?.[section],
      },
    }));
  }, []);

  const fetchChunkMetadata = useCallback(
    async (fileName) => {
      try {
        setLoadingChunks((prev) => ({ ...prev, [fileName]: true }));

        const startTime = Date.now();

        const response = await metadataService.listMetadataPostgres(
          deasyUserConfig.vdbmConfig?.LastActive,
          deasyUserConfig.deasyApiKey,
          "node",
          null,
          [fileName],
          null,
          null,
        );

        // Ensure minimum loading time of 300ms
        const elapsed = Date.now() - startTime;
        if (elapsed < 300) {
          await new Promise((resolve) => setTimeout(resolve, 300 - elapsed));
        }

        const transformedMetadata = {};
        if (response.metadata && response.metadata[fileName]) {
          Object.entries(response.metadata[fileName]).forEach(
            ([chunkId, chunkData]) => {
              const transformedTags = {};
              Object.entries(chunkData.tags || {}).forEach(
                ([tagName, tagData]) => {
                  transformedTags[tagName] = tagData.value;
                },
              );
              transformedMetadata[chunkId] = {
                tags: transformedTags,
              };
            },
          );
        }

        setChunkData((prev) => ({
          ...prev,
          [fileName]: transformedMetadata,
        }));
      } catch (error) {
        console.error("Error fetching chunk metadata:", error);
        setError(`Failed to load chunks for ${fileName}`);
      } finally {
        setLoadingChunks((prev) => ({ ...prev, [fileName]: false }));
      }
    },
    [deasyUserConfig, setLoadingChunks, setChunkData, setError],
  );

  // Moved from MetadataTable and converted to useCallback
  const toggleRowExpansion = useCallback(
    (fileName) => {
      setExpandedRow((prev) => {
        if (prev === fileName) {
          return null;
        }
        if (!chunkData[fileName]) {
          fetchChunkMetadata(fileName);
        }
        return fileName;
      });
    },
    [chunkData, fetchChunkMetadata, setExpandedRow],
  );

  const renderTableCell = (row, column) => (
    <CustomTableCell
      key={column}
      row={row}
      column={column}
      deasyFileKey={deasyFileKey}
      expandedRow={expandedRow}
      tableColumnWidths={{
        ...TABLE_COLUMN_WIDTHS,
        [deasyFileKey]: "200px",
      }}
      processTagValue={processTagValue}
      onTagClick={onTagClick}
    />
  );

  const isIrrelevantChunk = useCallback(
    (chunk) => {
      // Get selected tag columns (excluding the file key column)
      const selectedTags = displayColumns.filter(
        (column) => column !== deasyFileKey,
      );

      // Check if all selected tag cells show "Not found" or are empty
      return selectedTags.every((tagName) => {
        // If the tag doesn't exist in chunk.tags, consider it as "Not found"
        if (!chunk.tags || !(tagName in chunk.tags)) {
          return true;
        }
        const value = processTagValue(chunk.tags[tagName]);
        return value === "Not found" || !value;
      });
    },
    [displayColumns, deasyFileKey, processTagValue],
  );

  const groupChunks = useCallback(
    (chunks) => {
      const relevant = [];
      const irrelevant = [];

      Object.entries(chunks).forEach(([chunkId, chunk]) => {
        if (isIrrelevantChunk(chunk)) {
          irrelevant.push([chunkId, chunk]);
        } else {
          relevant.push([chunkId, chunk]);
        }
      });

      return { relevant, irrelevant };
    },
    [isIrrelevantChunk],
  );

  return (
    <MuiTableBody>
      {tableData.map((row) => (
        <React.Fragment key={row[deasyFileKey]}>
          {/* Main row */}
          <TableRow
            hover
            onClick={() => toggleRowExpansion(row[deasyFileKey])}
            sx={{
              cursor: "pointer",
              backgroundColor:
                expandedRow === row[deasyFileKey] ? "#F9FAFB" : "inherit",
              "&:hover": { backgroundColor: "#F3F4F6" },
              "& td": {
                borderBottom:
                  expandedRow === row[deasyFileKey]
                    ? "none"
                    : "1px solid #E5E7EB",
              },
            }}
          >
            {displayColumns.map((column) => renderTableCell(row, column))}
          </TableRow>

          {/* Expanded chunk rows */}
          {expandedRow === row[deasyFileKey] && (
            <TableRow>
              <TableCell colSpan={displayColumns.length} sx={{ padding: 0 }}>
                <Fade in={true} timeout={150}>
                  <div>
                    {loadingChunks[row[deasyFileKey]] ? (
                      <div className="py-2 bg-gray-50">
                        <div className="animate-pulse space-y-2">
                          {[...Array(3)].map((_, idx) => (
                            <div
                              key={idx}
                              className="flex items-center space-x-2 px-4"
                            >
                              {displayColumns.map((column) => (
                                <div
                                  key={column}
                                  style={{
                                    width:
                                      column === deasyFileKey
                                        ? TABLE_COLUMN_WIDTHS[deasyFileKey]
                                        : TABLE_COLUMN_WIDTHS.default,
                                    height: "32px",
                                  }}
                                  className="bg-gray-200 rounded"
                                />
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : chunkData[row[deasyFileKey]] ? (
                      <div className="bg-gray-50">
                        <table
                          className="w-full"
                          style={{ tableLayout: "fixed" }}
                        >
                          <colgroup>
                            {displayColumns.map((column) => (
                              <col
                                key={column}
                                style={{
                                  width:
                                    column === deasyFileKey
                                      ? TABLE_COLUMN_WIDTHS[deasyFileKey]
                                      : TABLE_COLUMN_WIDTHS.default,
                                  minWidth:
                                    column === deasyFileKey
                                      ? TABLE_COLUMN_WIDTHS[deasyFileKey]
                                      : TABLE_COLUMN_WIDTHS.default,
                                  maxWidth:
                                    column === deasyFileKey
                                      ? TABLE_COLUMN_WIDTHS[deasyFileKey]
                                      : TABLE_COLUMN_WIDTHS.default,
                                }}
                              />
                            ))}
                          </colgroup>
                          <tbody>
                            {(() => {
                              const { relevant, irrelevant } = groupChunks(
                                chunkData[row[deasyFileKey]],
                              );
                              const sectionState = expandedSections[
                                row[deasyFileKey]
                              ] || { relevant: true, irrelevant: false };

                              return (
                                <>
                                  {relevant.length > 0 && (
                                    <>
                                      <tr>
                                        <td colSpan={displayColumns.length}>
                                          <div
                                            className="py-2 px-4 bg-gray-100 text-sm font-medium text-gray-600 cursor-pointer hover:bg-gray-200 flex items-center justify-between"
                                            onClick={() =>
                                              toggleSection(
                                                row[deasyFileKey],
                                                "relevant",
                                              )
                                            }
                                          >
                                            <span>
                                              Relevant Chunks ({relevant.length}
                                              )
                                            </span>
                                            <span className="text-gray-500">
                                              {sectionState.relevant
                                                ? "▼"
                                                : "▶"}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                      {sectionState.relevant &&
                                        relevant.map(([chunkId, chunk]) => (
                                          <ChunkRow
                                            key={chunkId}
                                            metadata={{
                                              chunk,
                                              chunkId,
                                              row,
                                              displayColumns,
                                              deasyFileKey,
                                            }}
                                            layout={{
                                              TABLE_COLUMN_WIDTHS,
                                            }}
                                            textState={{
                                              activeFullText,
                                              toggleChunkText,
                                            }}
                                            evidenceState={{
                                              activeEvidence,
                                              showEvidence,
                                              handleFetchChunkEvidence,
                                              setShowEvidence,
                                              renderEvidenceRow,
                                              loadingEvidenceMap,
                                            }}
                                          />
                                        ))}
                                    </>
                                  )}
                                  {irrelevant.length > 0 && (
                                    <>
                                      <tr>
                                        <td colSpan={displayColumns.length}>
                                          <div
                                            className="py-2 px-4 bg-gray-100 text-sm font-medium text-gray-600 cursor-pointer hover:bg-gray-200 flex items-center justify-between"
                                            onClick={() =>
                                              toggleSection(
                                                row[deasyFileKey],
                                                "irrelevant",
                                              )
                                            }
                                          >
                                            <span>
                                              Irrelevant Chunks (
                                              {irrelevant.length})
                                            </span>
                                            <span className="text-gray-500">
                                              {sectionState.irrelevant
                                                ? "▼"
                                                : "▶"}
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                      {sectionState.irrelevant &&
                                        irrelevant.map(([chunkId, chunk]) => (
                                          <ChunkRow
                                            key={chunkId}
                                            metadata={{
                                              chunk,
                                              chunkId,
                                              row,
                                              displayColumns,
                                              deasyFileKey,
                                            }}
                                            layout={{
                                              TABLE_COLUMN_WIDTHS,
                                            }}
                                            textState={{
                                              activeFullText,
                                              toggleChunkText,
                                            }}
                                            evidenceState={{
                                              activeEvidence,
                                              showEvidence,
                                              handleFetchChunkEvidence,
                                              setShowEvidence,
                                              renderEvidenceRow,
                                              loadingEvidenceMap,
                                            }}
                                          />
                                        ))}
                                    </>
                                  )}
                                </>
                              );
                            })()}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="py-4 text-center text-gray-500 text-sm bg-gray-50">
                        No chunk metadata available
                      </div>
                    )}
                  </div>
                </Fade>
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      ))}
    </MuiTableBody>
  );
};

export default TableBody;
