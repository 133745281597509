import { useState, useEffect, useContext, useCallback } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { sourceMetadataService } from "../../../services/api";

const FileList = ({
  selectedFiles,
  setSelectedFiles,
  setError,
  showTitle = true,
  selectAllForEntireDB = false,
}) => {
  const ITEMS_PER_PAGE = 20;

  const { deasyUserConfig, deasyApiKey, vdbLastActiveProfileName } =
    useContext(BaseContext);
  const vectorDBConfiguration =
    deasyUserConfig?.vdbmConfig?.Configs?.[vdbLastActiveProfileName];
  const LIMIT = ITEMS_PER_PAGE;

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [previousOffset, setPreviousOffset] = useState(null);
  const [selectAllVisualOverride, setSelectAllVisualOverride] = useState(false);
  const [files, setFiles] = useState([]);
  const [outOfData, setOutOfData] = useState(false);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(true);

  // Filter files based on the user's search term
  const filteredFiles = files.filter((file) =>
    file.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Toggle an individual file selection or remove it from "all"
  const handleFileSelect = (file) => {
    if (selectedFiles === null) {
      // If "all" was selected, switching a single file turns the selection
      // into a normal array of everything except that file
      setSelectedFiles(filteredFiles.filter((f) => f !== file));
      setSelectAllVisualOverride(false);
      // Switch to Select All mode
      setIsSelectAll(true);
    } else {
      setSelectedFiles((prev) => {
        const newSelectedFiles =
          prev === null
            ? [file]
            : prev.includes(file)
              ? prev.filter((f) => f !== file)
              : [...prev, file];

        // If we've manually selected files, ensure button shows Deselect
        if (newSelectedFiles.length > 0) {
          setIsSelectAll(false);
        } else {
          // If no files are selected, switch back to Select All mode
          setIsSelectAll(true);
        }

        return newSelectedFiles;
      });
    }
  };

  /**
   * Generic fetch function for metadataService
   */
  const getFiles = useCallback(async () => {
    if (!deasyApiKey || !vectorDBConfiguration) {
      return { entities: [], next_offset: null };
    }
    try {
      setFetchingFiles(true);

      const response = await sourceMetadataService.listPaginated(
        deasyApiKey,
        vdbLastActiveProfileName,
        LIMIT,
        offset,
        "file",
        files,
        searchTerm,
      );

      if (response?.entities?.length) {
        // Add new unique entities to existing
        setFiles((prev) => [...new Set([...prev, ...response.entities])]);
        setOffset(response.next_offset);
      }

      // If next_offset is null, we know there's no more data
      if (response?.next_offset === null) {
        setOutOfData(true);
      }

      return {
        entities: response?.entities || [],
        next_offset: response?.next_offset || null,
      };
    } catch (err) {
      console.error("Error fetching files", err);
      setError(err.message);
      return { entities: [], next_offset: null };
    } finally {
      setFetchingFiles(false);
    }
  }, [
    vdbLastActiveProfileName,
    deasyApiKey,
    vectorDBConfiguration,
    offset,
    files,
    setError,
    LIMIT,
    searchTerm,
  ]);

  useEffect(() => {
    if (!deasyApiKey || !vectorDBConfiguration) return;
    (async () => {
      // Attempt to fetch some data if we don't have any
      if (files.length === 0 && !fetchingFiles && !outOfData) {
        const response = await getFiles();
        setPreviousOffset(response?.next_offset || null);
      }
    })();
  }, [
    getFiles,
    searchTerm,
    deasyApiKey,
    vectorDBConfiguration,
    outOfData,
    fetchingFiles,
    files.length,
  ]);

  useEffect(() => {
    if (!deasyApiKey || !vectorDBConfiguration) return;

    // If we already have enough files in local state for this page, do nothing
    if (page * ITEMS_PER_PAGE <= files.length) return;
    if (outOfData || fetchingFiles) return;

    const loadMore = async () => {
      const response = await getFiles();
      setPreviousOffset(response?.next_offset || null);
    };

    loadMore().catch((err) => {
      console.error("Error loading files for page change", err);
      setError(err.message);
    });
  }, [
    getFiles,
    page,
    files.length,
    outOfData,
    fetchingFiles,
    offset,
    deasyApiKey,
    vectorDBConfiguration,
    setError,
  ]);

  // Page navigation
  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = async () => {
    const totalPages = Math.ceil(files.length / ITEMS_PER_PAGE);

    // Go to next page if it exists in memory
    const newPage = page + 1;
    if (newPage <= totalPages) {
      setPage(newPage);
    } else {
      // If we are out of memory data but have a previousOffset, try loading more
      if (!outOfData && previousOffset && !fetchingFiles) {
        const response = await getFiles();
        setPreviousOffset(response.next_offset || null);

        // Then move to the newly-populated page
        setPage(newPage);
      }
    }
  };

  // Compute what chunk of filteredFiles to display on this page
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedFiles = filteredFiles.slice(startIndex, endIndex);

  const handleSearch = async () => {
    setFiles([]);
    setOffset(0);
    setPage(1);
    setOutOfData(false);
  };

  return (
    <div className="h-full flex flex-col">
      {/* Header section - fixed height */}
      <div className="shrink-0">
        {/* Title and count */}
        {showTitle && (
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium">1. Select Files</h3>
            <span className="text-sm text-gray-600">
              Selected: {selectedFiles?.length ?? 0} files
            </span>
          </div>
        )}

        {/* Search Bar */}
        <div className="mb-4 flex gap-2 items-center">
          <input
            type="text"
            placeholder="Search files..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <button
            onClick={handleSearch}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
            disabled={fetchingFiles}
          >
            Search
          </button>
          {selectAllForEntireDB && (
            <button
              onClick={() => {
                // Simply toggle the button mode
                if (isSelectAll) {
                  // We're selecting all
                  setSelectedFiles(null);
                  setSelectAllVisualOverride(true);
                  // Switch to deselect mode
                  setIsSelectAll(false);
                } else {
                  // We're deselecting all
                  setSelectedFiles([]);
                  setSelectAllVisualOverride(false);
                  // Switch to select mode
                  setIsSelectAll(true);
                }
              }}
              className={`px-4 whitespace-nowrap py-2 border   border-gray-300 rounded-md text-sm transform-all hover: ${isSelectAll ? "bg-primary text-white" : "bg-gray-100 text-gray-800"} `}
              disabled={filteredFiles.length === 0}
            >
              {/* Button text is controlled ONLY by our toggle state */}
              {isSelectAll
                ? `Select All ${searchTerm ? "Filtered" : ""}`
                : `Deselect All ${searchTerm ? "Filtered" : ""}`}
            </button>
          )}
        </div>

        {/* Quick Actions - only show when there's search content */}
        {searchTerm && (
          <div className="flex justify-end items-center mb-4">
            <span className="text-sm text-gray-500">
              Showing {filteredFiles.length} of {files.length} files
            </span>
          </div>
        )}
      </div>

      {/* Main Container with list + pagination */}
      <div className="flex-1 min-h-0 border rounded-md bg-white overflow-hidden">
        {files.length === 0 ? (
          fetchingFiles ? (
            <div className="flex items-center justify-center h-32">
              <span className="loading loading-spinner loading-md"></span>
              <span className="ml-2 text-gray-600">Loading files...</span>
            </div>
          ) : (
            <div className="flex items-center justify-center h-32 text-gray-500">
              No files available
            </div>
          )
        ) : (
          <div className="h-full flex flex-col">
            {/* Scrollable file list */}
            <div className="flex-1 overflow-y-auto">
              <div className="divide-y divide-gray-100">
                {paginatedFiles.map((file) => {
                  const isSelected =
                    selectedFiles?.includes(file) || selectAllVisualOverride;
                  return (
                    <div
                      key={file}
                      onClick={() => handleFileSelect(file)}
                      className={`px-4 py-3 cursor-pointer hover:opacity-80 transition-colors w-full ${
                        isSelected ? "bg-gray-200" : ""
                      }`}
                      data-file={file}
                    >
                      <div className="flex flex-col w-full" title={file}>
                        <span className="text-sm font-medium text-gray-700 truncate text-left">
                          {file.split("/").pop()}
                        </span>
                        <span className="text-xs text-gray-500 truncate text-left mt-1">
                          {file}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Pagination with loading overlay when fetching */}
            <div className="shrink-0 flex justify-between items-center p-4 border-t bg-white relative">
              <button
                onClick={handlePrevPage}
                disabled={page === 1 || fetchingFiles}
                className="px-3 py-1 border border-gray-300 rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 flex items-center gap-2"
              >
                <FiChevronLeft size={16} />
                Back
              </button>
              <span className="text-sm text-gray-600">Page {page}</span>
              <button
                onClick={handleNextPage}
                disabled={
                  fetchingFiles || (outOfData && endIndex >= files.length)
                }
                className="px-3 py-1 border border-gray-300 rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 flex items-center gap-2"
              >
                Next
                <FiChevronRight size={16} />
              </button>
              {fetchingFiles && (
                <div className="absolute inset-0 bg-white/50 flex items-center justify-center">
                  <span className="loading loading-spinner loading-sm"></span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileList;
