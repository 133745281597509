import React, { useState, useContext, useRef, useEffect } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaPlus,
  FaTrash,
  FaEdit,
  FaSearch,
} from "react-icons/fa";
import { GraphContext } from "../../../../../../contexts/GraphContext";
import { BaseContext } from "../../../../../../contexts/BaseContext";
import { graphService } from "../../../../../../services/api";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-hot-toast";
import { Tooltip } from "@mui/material";

const GraphSelector = ({ viewOnly = false, verbose = true }) => {
  const dropdownRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [editingGraph, setEditingGraph] = useState(null);
  const [graphStartingName, setGraphStartingName] = useState("");
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isChangingGraph, setIsChangingGraph] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "updated_at",
    direction: "desc",
  });
  const {
    graphs,
    selectedDiscoveryGraph,
    setSelectedDiscoveryGraph,
    setGraphs,
    handleSaveGraph,
    fetchGraphs,
    setShouldFitView,
  } = useContext(GraphContext);
  const { deasyUserConfig } = useContext(BaseContext);

  useEffect(() => {
    if (!selectedDiscoveryGraph?.graph_name) {
      fetchGraphs();
    }
  }, [selectedDiscoveryGraph, fetchGraphs]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsExpanded(false);
        setEditingGraph(null);
        setIsCreatingNew(false);
      }
    };

    if (isExpanded) {
      document.addEventListener("mousedown", handleClickOutside);
      const handleEscape = (e) => {
        if (e.key === "Escape") {
          setIsExpanded(false);
          setEditingGraph(null);
          setIsCreatingNew(false);
        }
      };
      document.addEventListener("keydown", handleEscape);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleEscape);
      };
    }
  }, [isExpanded]);

  const handleCreateGraph = async () => {
    setIsExpanded(true);
    setIsCreatingNew(true);
    const newGraphName = `Schema ${graphs.length + 1}`;
    const newDiscoveryGraph = {
      graph_id: String(uuidv4()),
      graph_name: newGraphName,
      graph_description: "",
      graph_data: {},
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
    setEditingGraph(newDiscoveryGraph);
    setGraphStartingName(newGraphName);
  };

  const handleDeleteGraph = async (graphName, e) => {
    e.stopPropagation();
    if (window.confirm("Are you sure you want to delete this graph?")) {
      await graphService.deleteGraph(graphName, deasyUserConfig.deasyApiKey);
      setGraphs((prev) => prev.filter((g) => g.graph_name !== graphName));
      if (selectedDiscoveryGraph?.graph_name === graphName) {
        setSelectedDiscoveryGraph(null);
      }
    }
  };
  const handleEditGraph = async (e) => {
    e.preventDefault();
    try {
      await graphService.createUpdateGraph(
        deasyUserConfig.deasyApiKey,
        graphStartingName,
        editingGraph.graph_description,
        editingGraph.graph_data,
        editingGraph.graph_name,
      );

      const updatedGraphs = await graphService.getGraphs(
        deasyUserConfig.deasyApiKey,
      );
      setGraphs(updatedGraphs.data.graphs);

      if (isCreatingNew) {
        setSelectedDiscoveryGraph(editingGraph);
        setShouldFitView(true);
      } else {
        setGraphs((prev) =>
          prev.map((g) =>
            g.graph_id === editingGraph.graph_id ? editingGraph : g,
          ),
        );
      }
      setEditingGraph(null);
      setIsCreatingNew(false);
      setIsExpanded(false);
    } catch (error) {
      console.error("Error updating graph:", error);
      toast.error("Failed to save graph changes");
    }
  };

  const handleStartEdit = (graph, e) => {
    e.stopPropagation();
    setEditingGraph({ ...graph });
    setGraphStartingName(graph.graph_name);
  };

  const handleChangeGraph = async (graph) => {
    if (
      selectedDiscoveryGraph?.graph_id !== graph.graph_id &&
      !isChangingGraph
    ) {
      setIsChangingGraph(true);
      try {
        await handleSaveGraph();
        setSelectedDiscoveryGraph(graph);

        if (graph?.graph_id) {
          localStorage.setItem("lastActiveGraphId", graph.graph_id);
        }

        setShouldFitView(true);
        setIsExpanded(false);
      } catch (error) {
        console.error("Error changing graph:", error);
        toast.error("Failed to change graph");
      } finally {
        setTimeout(() => {
          setIsChangingGraph(false);
        }, 500);
      }
    } else {
      setIsExpanded(false);
    }
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortedGraphs = (graphs) => {
    const sortableGraphs = [...graphs];
    if (sortConfig.key) {
      sortableGraphs.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableGraphs;
  };

  const filteredGraphs = getSortedGraphs(
    graphs.filter((graph) => {
      const query = searchQuery.toLowerCase();
      return (
        graph.graph_name.toLowerCase().includes(query) ||
        graph.graph_description.toLowerCase().includes(query)
      );
    }),
  );

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <FaChevronUp className="inline-block ml-1 text-xs" />
      ) : (
        <FaChevronDown className="inline-block ml-1 text-xs" />
      );
    }
    return null;
  };

  return (
    <div className={`relative w-full`} ref={dropdownRef}>
      <div className="flex items-center gap-2">
        <Tooltip
          title={selectedDiscoveryGraph?.graph_name || "Select a Schema"}
          arrow
          placement="bottom"
          slotProps={{
            popper: {
              sx: {
                "& .MuiTooltip-tooltip": {
                  bgcolor: "rgba(0, 0, 0, 0.9)",
                  padding: "8px 12px",
                  fontSize: "0.75rem",
                  borderRadius: "6px",
                  maxWidth: "300px",
                },
                "& .MuiTooltip-arrow": {
                  color: "rgba(0, 0, 0, 0.9)",
                },
              },
            },
          }}
        >
          <button
            onClick={() => !isChangingGraph && setIsExpanded(!isExpanded)}
            disabled={isChangingGraph}
            className={`flex-1 flex items-center justify-between px-4 py-2.5 font-medium hover:bg-white rounded-lg border border-gray-200 transition-all w-full duration-300 ${
              viewOnly
                ? "h-8 text-gray-600 text-xs"
                : "h-11 text-gray-700 text-sm shadow-sm hover:shadow-md"
            } ${
              isChangingGraph
                ? "opacity-70 cursor-not-allowed bg-gray-50"
                : "bg-white hover:border-gray-300"
            }`}
          >
            <span className="truncate max-w-[200px]">
              {isChangingGraph ? (
                <motion.div
                  initial={{ opacity: 0.7 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    repeat: Infinity,
                    duration: 0.8,
                    repeatType: "reverse",
                  }}
                  className="flex items-center"
                >
                  <span className="mr-2">Loading...</span>
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{
                      repeat: Infinity,
                      duration: 1,
                      ease: "linear",
                    }}
                    className="h-3 w-3 border-2 border-t-transparent border-primary rounded-full"
                  />
                </motion.div>
              ) : selectedDiscoveryGraph?.graph_id === null ? (
                "No schema selected"
              ) : (
                selectedDiscoveryGraph?.graph_name || "Select a Schema"
              )}
            </span>
            {isExpanded ? (
              <FaChevronUp className="ml-2 shrink-0" />
            ) : (
              <FaChevronDown className="ml-2 shrink-0" />
            )}
          </button>
        </Tooltip>
        {!viewOnly && (
          <button
            onClick={handleCreateGraph}
            disabled={isChangingGraph}
            className={`h-11 px-4 py-2.5 bg-white text-primary border border-primary rounded-lg hover:bg-primary hover:text-white transition-all duration-300 flex items-center gap-2 text-sm font-medium whitespace-nowrap shadow-sm hover:shadow-md ${
              isChangingGraph ? "opacity-70 cursor-not-allowed" : ""
            }`}
          >
            <FaPlus className="shrink-0" size={14} />
            <span className="truncate">New Metadata Schema</span>
          </button>
        )}
      </div>

      <AnimatePresence>
        {isExpanded && (
          <>
            <div
              className={!viewOnly ? "fixed inset-0 bg-black/10 z-40" : "fixed"}
              onClick={() => {
                setIsExpanded(false);
                setEditingGraph(null);
                setIsCreatingNew(false);
                setGraphStartingName("");
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
              className={`absolute z-50 mt-2 bg-white border border-gray-100 rounded-xl shadow-xl ring-1 ring-black/5 overflow-hidden ${viewOnly ? "min-w-[20vw]" : "w-[40vw]"}`}
            >
              <AnimatePresence>
                {editingGraph && !viewOnly && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    className="p-3 border-b bg-gray-50/80 backdrop-blur-sm"
                  >
                    <form onSubmit={handleEditGraph} className="space-y-2">
                      <input
                        autoFocus
                        type="text"
                        value={editingGraph.graph_name}
                        onChange={(e) =>
                          setEditingGraph({
                            ...editingGraph,
                            graph_name: e.target.value,
                          })
                        }
                        className="w-full px-2 py-1 text-sm border rounded"
                        placeholder="Schema Name"
                      />
                      <textarea
                        value={editingGraph.graph_description}
                        onChange={(e) =>
                          setEditingGraph({
                            ...editingGraph,
                            graph_description: e.target.value,
                          })
                        }
                        className="w-full px-2 py-1 text-sm border rounded"
                        placeholder="Description"
                        rows={2}
                      />
                      <div className="flex gap-2">
                        <button
                          type="submit"
                          className="px-2 py-1 text-sm bg-primary text-white rounded hover:bg-emerald-700"
                        >
                          {isCreatingNew ? "Create" : "Save"}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setEditingGraph(null);
                            setGraphStartingName("");
                            setIsCreatingNew(false);
                            if (isCreatingNew) setIsExpanded(false);
                          }}
                          className="px-2 py-1 text-sm border rounded hover:bg-gray-50"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </motion.div>
                )}
              </AnimatePresence>

              <div className="p-3 border-b border-gray-100">
                <div className="relative">
                  <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 text-sm" />
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search schemas..."
                    className="w-full pl-10 pr-4 py-2.5 text-sm border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary/30 focus:border-primary/50 transition-all"
                  />
                </div>
              </div>

              <div className="overflow-y-auto shadow-inner max-h-[300px] rounded-b-xl">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 text-left sticky top-0 z-10">
                    <tr>
                      <th
                        className="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer hover:bg-gray-100/80 transition-colors"
                        onClick={() => requestSort("graph_name")}
                      >
                        <div className="flex items-center">
                          Schema Name
                          <span className="ml-1.5">
                            {getSortIndicator("graph_name")}
                          </span>
                        </div>
                      </th>
                      {verbose && (
                        <th
                          className="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer hover:bg-gray-100/80 transition-colors"
                          onClick={() => requestSort("graph_description")}
                        >
                          <div className="flex items-center">
                            Description
                            <span className="ml-1.5">
                              {getSortIndicator("graph_description")}
                            </span>
                          </div>
                        </th>
                      )}
                      {verbose && (
                        <th
                          className="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer hover:bg-gray-100/80 transition-colors"
                          onClick={() => requestSort("created_at")}
                        >
                          <div className="flex items-center">
                            Created
                            <span className="ml-1.5">
                              {getSortIndicator("created_at")}
                            </span>
                          </div>
                        </th>
                      )}
                      {verbose && (
                        <th
                          className="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer hover:bg-gray-100/80 transition-colors"
                          onClick={() => requestSort("updated_at")}
                        >
                          <div className="flex items-center">
                            Updated
                            <span className="ml-1.5">
                              {getSortIndicator("updated_at")}
                            </span>
                          </div>
                        </th>
                      )}
                      {!viewOnly && (
                        <th className="px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          Actions
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200/80 text-left">
                    {viewOnly && (
                      <tr
                        onClick={() =>
                          handleChangeGraph({
                            graph_id: null,
                            graph_name: "No schema selected",
                            graph_data: {},
                          })
                        }
                        className={`cursor-pointer hover:bg-gray-50/50 transition-colors duration-300 group ${
                          !selectedDiscoveryGraph ||
                          selectedDiscoveryGraph?.graph_id === null
                            ? "bg-primary/5 border-l-2 border-primary"
                            : ""
                        } ${isChangingGraph ? "pointer-events-none opacity-70" : ""}`}
                      >
                        <td className="px-4 py-2.5 whitespace-nowrap text-sm font-medium text-gray-900 max-w-[10px]">
                          <span className="text-gray-500 italic">
                            No schema selected
                          </span>
                        </td>
                        {verbose && (
                          <td className="px-4 py-2.5 text-sm text-gray-500">
                            Show all tags without filtering by graph
                          </td>
                        )}
                        {verbose && (
                          <td className="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500">
                            -
                          </td>
                        )}
                        {verbose && (
                          <td className="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500">
                            -
                          </td>
                        )}
                      </tr>
                    )}
                    {filteredGraphs.map((graph) => (
                      <tr
                        key={graph.graph_id}
                        onClick={() => handleChangeGraph(graph)}
                        className={`cursor-pointer hover:bg-gray-50/50 transition-colors duration-300 group ${
                          selectedDiscoveryGraph?.graph_id === graph.graph_id
                            ? "bg-primary/5 border-l-2 border-primary"
                            : ""
                        } ${isChangingGraph ? "pointer-events-none opacity-70" : ""}`}
                      >
                        <td className="px-4 py-2.5 whitespace-nowrap text-sm font-medium text-gray-900 max-w-[10px]">
                          <div className="flex items-center gap-2">
                            {selectedDiscoveryGraph?.graph_id ===
                              graph.graph_id && (
                              <span
                                className="h-2.5 w-2.5 rounded-full bg-green-500 flex-shrink-0"
                                title="Currently active schema"
                              ></span>
                            )}
                            <span
                              className={
                                selectedDiscoveryGraph?.graph_id ===
                                graph.graph_id
                                  ? "font-semibold text-primary"
                                  : ""
                              }
                            >
                              {graph.graph_name}
                            </span>
                          </div>
                        </td>
                        {verbose && (
                          <td className="px-4 py-2.5 text-sm text-gray-500">
                            {graph.graph_description}
                          </td>
                        )}
                        {verbose && (
                          <td className="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500">
                            {format(new Date(graph.created_at), "MMM d, yyyy")}
                          </td>
                        )}
                        {verbose && (
                          <td className="px-4 py-2.5 whitespace-nowrap text-sm text-gray-500">
                            {format(new Date(graph.updated_at), "MMM d, yyyy")}
                          </td>
                        )}
                        {!viewOnly && (
                          <td className="px-4 py-2.5 whitespace-nowrap text-sm font-medium">
                            <div className="flex gap-1.5">
                              <button
                                onClick={(e) => handleStartEdit(graph, e)}
                                disabled={isChangingGraph}
                                className={`h-7 w-7 p-1.5 rounded-lg text-gray-400 hover:text-primary hover:bg-primary/10 transition-colors duration-300 grid place-items-center ${
                                  isChangingGraph
                                    ? "opacity-70 cursor-not-allowed"
                                    : ""
                                }`}
                                title="Edit graph"
                              >
                                <FaEdit className="h-[12px] w-[12px]" />
                              </button>
                              <button
                                onClick={(e) =>
                                  handleDeleteGraph(graph.graph_name, e)
                                }
                                disabled={isChangingGraph}
                                className={`h-7 w-7 p-1.5 rounded-lg text-gray-400 hover:text-red-500 hover:bg-red-50 transition-colors duration-300 grid place-items-center ${
                                  isChangingGraph
                                    ? "opacity-70 cursor-not-allowed"
                                    : ""
                                }`}
                                title="Delete graph"
                              >
                                <FaTrash className="h-[12px] w-[12px]" />
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                    {filteredGraphs.length === 0 && (
                      <tr>
                        <td
                          colSpan={(verbose ? 4 : 1) + (!viewOnly ? 1 : 0)}
                          className="px-4 py-4 text-center text-gray-500"
                        >
                          No graphs found matching your search.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default GraphSelector;
