import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { FaPlus } from "react-icons/fa";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { metadataService, tagService } from "../../../../../services/api";
import { deasyFileKey } from "../../../../../config/config";
import {
  Table,
  TableContainer,
  TablePagination,
  Paper,
  CircularProgress,
} from "@mui/material";
import { FaSync, FaFilter } from "react-icons/fa";
import MetadataColumnSelector from "./components/MetadataColumnSelector";
import CreateDataSlice from "../../../../utils/CreateDataSlice";
import { WorkflowType } from "../../../../../models/WorkflowModel";
import DistributionChart from "./components/DistributionChart";
import {
  processTagValue,
  TABLE_COLUMN_WIDTHS,
  ActiveFilters,
  ExpandableSection,
  fetchChunkEvidence,
  handleDataSliceChange,
  extractTagsFromSchema,
} from "./metadataTableUtils";
import BlankMetadataScreen from "./BlankMetadataScreen";
import { nodePathToConditions } from "../DataDiscovery/GraphUtils";
import CompactDataSliceSelector from "./CompactDataSliceSelector";
import FilterMenu from "./components/FilterMenu";
import EvidenceRow from "./components/EvidenceRow";
import TableBody from "./components/TableBody";
import TableHeader from "./components/TableHeader";
import { StandardizeValuesPanel } from "../DataDiscovery/Standardization/StandardizeValuesPanel";
import { toast } from "react-hot-toast";
import DeleteMetadataConfirmationModal from "./components/DeleteMetadataConfirmationModal";
import TagEvidence from "./components/TagEvidenceModal";
import GraphSelector from "../DataDiscovery/Graph/GraphSelector";
import { GraphContext } from "../../../../../contexts/GraphContext";
import * as Sentry from "@sentry/react";

const MetadataTable = ({
  backendFilters,
  onFiltersChange,
  dataslice_id = null,
  compact = false,
  source = "viewMetadata",
  nodeData = null,
  selectedColumns: externalSelectedColumns = null,
  availableColumns: externalAvailableColumns = null,
  showAllColumns: externalShowAllColumns = null,
  vector_db_config = null,
  loadedOnce = false,
  conditionsNew = null,
  setConditionsNew = null,
}) => {
  const {
    deasyUserConfig,
    isConfigRefreshing,
    dataSlices,
    setCurrentScreen,
    vdbFilesCount,
    savedTags,
    vdbLastActiveProfileName,
  } = useContext(BaseContext);
  const { selectedDiscoveryGraph } = useContext(GraphContext);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [refreshKey, setRefreshKey] = useState(0);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);
  const [loadingValues] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [globalDistributions, setGlobalDistributions] = useState({});
  const [filteredDistributions, setFilteredDistributions] = useState({});
  const [chunkData, setChunkData] = useState({});
  const [loadingChunks, setLoadingChunks] = useState({});
  const [loadingDistributions, setLoadingDistributions] = useState(true);
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(() => {
    const initialColumns = [deasyFileKey];
    Object.keys(backendFilters).forEach((column) => {
      if (!initialColumns.includes(column)) {
        initialColumns.push(column);
      }
    });
    return initialColumns;
  });
  const [availableColumns, setAvailableColumns] = useState([]);
  const [expandedSections, setExpandedSections] = useState({
    controls: false,
    filters: false,
    help: false,
  });
  const [distributionLimit] = useState(10);
  const [showDistributions] = useState(true);
  const [showCreateDataSlice, setShowCreateDataSlice] = useState(false);
  const [activeEvidence, setActiveEvidence] = useState(null);
  const [showEvidence, setShowEvidence] = useState(false);
  const [activeFullText, setActiveFullText] = useState(null);
  const [, setVisibleItemsCount] = useState({});
  const [evidenceRefs] = useState(() => new Map());
  const [loadingEvidenceMap, setLoadingEvidenceMap] = useState({});
  const [selectedDataSlice, setSelectedDataSlice] = useState(null);
  const [filterSearch, setFilterSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [inputSearchQuery, setInputSearchQuery] = useState("");
  const debouncedExpandedSections = expandedSections;
  const [activeStandardizeColumn, setActiveStandardizeColumn] = useState(null);
  const [, setStandardizeTagStats] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tagsToDelete, setTagsToDelete] = useState([]);
  const [deleteStats, setDeleteStats] = useState({});
  const [tagEvidence, setTagEvidence] = useState({
    isOpen: false,
    tagName: null,
    value: null,
    fileName: null,
  });
  // this is a nested structure in the form of {CONDITIONAL (OR/AND): [{tag_name:[tag_value1, tag_value2]}, {CONDITIONAL (OR/AND): [{tag_name:[tag_value1, tag_value2]}, {CONDITIONAL (OR/AND): [{tag_name:[tag_value1, tag_value2]}]}
  // the conditionals are the top level keys, the second level keys are the tag_name and the values are the tag_values

  // Add a ref to track if the component is mounted
  const isMounted = useRef(true);

  // If external props are provided, use them instead of internal state.
  const effectiveAvailableColumns =
    externalAvailableColumns || availableColumns;
  const effectiveShowAllColumns =
    externalShowAllColumns !== null ? externalShowAllColumns : showAllColumns;

  const handleSectionToggle = React.useCallback((section) => {
    setExpandedSections((prev) => {
      const newState = { ...prev };

      // If opening the controls section (Add columns), close the filters section
      if (section === "controls" && !prev[section]) {
        newState.filters = false;
      }

      newState[section] = !prev[section];
      return newState;
    });
  }, []);

  const handleColumnSelection = React.useCallback(
    (columns) => {
      // If columns is empty array (deselect all case), only keep deasyFileKey
      if (columns.length === 0) {
        setSelectedColumns([deasyFileKey]);
        setShowAllColumns(false);
        return;
      }

      // Ensure deasyFileKey is always included
      if (!columns.includes(deasyFileKey)) {
        columns.unshift(deasyFileKey);
      }

      setSelectedColumns(columns);
      // Update showAllColumns based on whether all columns are selected
      setShowAllColumns(
        columns.length === effectiveAvailableColumns.length + 1,
      ); // +1 for deasyFileKey
    },
    [effectiveAvailableColumns],
  );

  const handleSelectAll = React.useCallback(() => {
    const allColumns = [deasyFileKey, ...effectiveAvailableColumns];
    setSelectedColumns(allColumns);
    setShowAllColumns(true);
  }, [effectiveAvailableColumns]);

  const handleDeselectAll = React.useCallback(() => {
    setSelectedColumns([deasyFileKey]);
    setShowAllColumns(false);
  }, []);

  const handleDeleteTags = useCallback(
    async (tags) => {
      try {
        const statsPromises = tags.map(async (tag) => {
          try {
            const result = await tagService.getTagDeleteStats(
              deasyUserConfig.deasyApiKey,
              tag,
            );
            return { tag, data: result.data, error: null };
          } catch (error) {
            return { tag, data: null, error: error };
          }
        });
        const statsResults = await Promise.all(statsPromises);
        const failedRequests = statsResults.filter((result) => result.error);
        if (failedRequests.length > 0) {
          throw new Error(
            `Failed to fetch stats for tags: ${failedRequests.map((r) => r.tag).join(", ")}`,
          );
        }
        const combinedStats = statsResults.reduce((acc, { tag, data }) => {
          acc[tag] = data;
          return acc;
        }, {});

        setDeleteStats(combinedStats);
        setTagsToDelete(tags);
        setShowDeleteModal(true);
      } catch (error) {
        console.error("Error fetching tag stats:", error);
        toast.error("Failed to prepare tag deletion. Please try again.");
      }
    },
    [deasyUserConfig.deasyApiKey],
  );

  const columnSelectorProps = React.useMemo(
    () => ({
      availableColumns,
      selectedColumns,
      setSelectedColumns: handleColumnSelection,
      showAllColumns,
      setShowAllColumns: (show) => {
        if (show) {
          handleSelectAll();
        } else {
          handleDeselectAll();
        }
      },
      activeFilters: backendFilters,
      distributions: filteredDistributions,
      globalDistributions: globalDistributions,
      key: refreshKey,
      onDeleteTags: handleDeleteTags,
      dataslice_id,
      node_condition: nodeData ? nodePathToConditions(nodeData.nodePath) : null,
    }),
    [
      availableColumns,
      selectedColumns,
      showAllColumns,
      handleColumnSelection,
      handleSelectAll,
      handleDeselectAll,
      backendFilters,
      filteredDistributions,
      globalDistributions,
      refreshKey,
      handleDeleteTags,
      dataslice_id,
      nodeData,
    ],
  );

  const displayColumns = useMemo(() => {
    // If external columns are provided, use those
    if (externalSelectedColumns) {
      return externalSelectedColumns;
    }

    // Otherwise use selected columns, ensuring deasyFileKey is always first
    const columns = selectedColumns.includes(deasyFileKey)
      ? selectedColumns
      : [deasyFileKey, ...selectedColumns];

    // If showAllColumns is true, show all available columns
    if (effectiveShowAllColumns) {
      return [deasyFileKey, ...effectiveAvailableColumns];
    }

    return columns;
  }, [
    externalSelectedColumns,
    selectedColumns,
    effectiveShowAllColumns,
    effectiveAvailableColumns,
  ]);

  const handleRemoveFilter = (column, value) => {
    // Update the flat filter structure
    onFiltersChange((prev) => {
      const newFilters = { ...prev };
      if (newFilters[column]) {
        // If the value is a string, it's a simple filter
        if (typeof value === "string") {
          newFilters[column] = newFilters[column].filter((v) => {
            // Handle both string values and object values with operators
            if (typeof v === "string") {
              return v !== value;
            } else if (v && typeof v === "object") {
              return v.value !== value;
            }
            return true;
          });
        }
        // If the value is an object with operator info, filter by the value and operator
        else if (value && typeof value === "object" && value.value) {
          newFilters[column] = newFilters[column].filter((v) => {
            if (typeof v === "string") {
              return true; // Keep string values
            } else if (v && typeof v === "object") {
              // Match both value and operator for removal
              return !(
                v.value === value.value && v.operator === value.operator
              );
            }
            return true;
          });
        }

        if (newFilters[column].length === 0) {
          delete newFilters[column];
        }
      }
      return newFilters;
    });

    // Update the conditionals_new structure
    setConditionsNew((prev) => {
      if (!prev) return null;

      // Helper function to recursively remove a tag/value from the structure
      const removeTagValueFromStructure = (node) => {
        if (!node) return null;

        // If this is a tag node with the value to remove
        if (node.tag && node.tag.name === column) {
          // For normal string values
          if (typeof value === "string" && node.tag.values.includes(value)) {
            return null; // Remove this node
          }
          // For object values with operators
          else if (value && typeof value === "object" && value.value) {
            const matchesOperator = node.tag.operator === value.operator;
            const matchesValue = node.tag.values.includes(value.value);

            if (matchesOperator && matchesValue) {
              return null; // Remove this node
            }
          }
        }

        // If this is a group node, process its children
        if (node.children) {
          // Process children and filter out nulls
          const newChildren = node.children
            .map(removeTagValueFromStructure)
            .filter(Boolean);

          // If no children left, return null
          if (newChildren.length === 0) return null;

          // Otherwise return updated node
          return {
            ...node,
            children: newChildren,
          };
        }
        return node;
      };

      // Process the entire structure
      const updatedStructure = removeTagValueFromStructure({ ...prev });

      // If root is null or has no children, return null
      if (
        !updatedStructure ||
        !updatedStructure.children ||
        updatedStructure.children.length === 0
      ) {
        return null;
      }
      return updatedStructure;
    });

    setRefreshKey((prev) => prev + 1);
    setPage(0);
  };

  const handleClearFilters = () => {
    onFiltersChange({});
    setPage(0);
    setConditionsNew(null);
    setRefreshKey((prev) => prev + 1);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefresh = () => {
    // Clear all filters
    onFiltersChange({});
    // Reset data slice selection
    setSelectedDataSlice(null);
    // Reset page
    setPage(0);
    // Reset expanded sections
    setExpandedSections((prev) => ({
      ...prev,
      filters: false,
    }));
    // Refresh data
    setRefreshKey((prev) => prev + 1);
  };

  const handleDistributionClick = async (column, value) => {
    try {
      setLoading(true);

      const newFilters = { ...backendFilters };
      if (!newFilters[column]) {
        newFilters[column] = [];
      }

      const valueIndex = newFilters[column].findIndex((v) => {
        if (typeof v === "string") return v === value;
        if (typeof v === "object" && v.value) return v.value === value;
        return false;
      });

      if (valueIndex === -1) {
        // Adding a filter
        newFilters[column] = [...newFilters[column], value];
        // Force expand the filters section when adding a new filter
        setExpandedSections((prev) => ({
          ...prev,
          filters: true,
        }));
      } else {
        // Removing a filter
        newFilters[column] = [
          ...newFilters[column].slice(0, valueIndex),
          ...newFilters[column].slice(valueIndex + 1),
        ];

        if (newFilters[column].length === 0) {
          delete newFilters[column];
        }

        // Update the conditionals_new structure when removing a filter
        setConditionsNew((prev) => {
          if (!prev) return null;

          // Helper function to recursively remove a tag/value from the structure
          const removeTagValueFromStructure = (node) => {
            if (!node) return null;

            // If this is a tag node with the value to remove
            if (
              node.tag &&
              node.tag.name === column &&
              node.tag.values.includes(value)
            ) {
              return null; // Remove this node
            }

            // If this is a group node, process its children
            if (node.children) {
              // Process children and filter out nulls
              const newChildren = node.children
                .map(removeTagValueFromStructure)
                .filter(Boolean);

              // If no children left, return null
              if (newChildren.length === 0) return null;

              // Otherwise return updated node
              return {
                ...node,
                children: newChildren,
              };
            }
            return node;
          };

          // Process the entire structure
          const updatedStructure = removeTagValueFromStructure({ ...prev });

          // If root is null or has no children, return null
          if (
            !updatedStructure ||
            !updatedStructure.children ||
            updatedStructure.children.length === 0
          ) {
            return null;
          }
          return updatedStructure;
        });
      }

      onFiltersChange(newFilters);
      setPage(0);
    } catch (error) {
      console.error("Error applying distribution filter:", error);
      setError("Failed to apply distribution filter");
    } finally {
      setLoading(false);
    }
  };

  const toggleDistributionExpansion = (column, direction = "more") => {
    setVisibleItemsCount((prev) => {
      const currentCount = prev[column] || distributionLimit;

      if (direction === "more") {
        // Simply add 10 more without checking total
        return { ...prev, [column]: currentCount + 10 };
      } else {
        // Reduce by 10, but don't go below minimum
        return {
          ...prev,
          [column]: Math.max(currentCount - 10, distributionLimit),
        };
      }
    });
  };

  const renderDistributionChart = (column) => {
    if (!showDistributions) return null;

    if (loadingDistributions) {
      return (
        <div className="h-full w-full flex items-center justify-center">
          <CircularProgress size={20} sx={{ color: "#9ca3af" }} />
        </div>
      );
    }

    const processedCurrentDistributions = filteredDistributions[column] || {};
    const globalColumnDistributions = globalDistributions[column] || {};

    if (
      (!processedCurrentDistributions ||
        Object.keys(processedCurrentDistributions).length === 0) &&
      (!globalColumnDistributions ||
        Object.keys(globalColumnDistributions).length === 0)
    ) {
      return (
        <div className="h-full w-full flex items-center justify-center text-gray-400 text-xs">
          No data
        </div>
      );
    }

    return (
      <DistributionChart
        column={column}
        currentDistributions={processedCurrentDistributions}
        globalColumnDistributions={globalColumnDistributions}
        backendFilters={backendFilters}
        distributionLimit={distributionLimit}
        compactMode={compact}
        onDistributionClick={handleDistributionClick}
        onToggleExpansion={toggleDistributionExpansion}
      />
    );
  };

  const toggleChunkText = (chunkId) => {
    setActiveFullText((prev) => (prev === chunkId ? null : chunkId));
  };

  const CreateDataSliceButton = () => {
    if (Object.keys(backendFilters).length === 0) return null;

    return (
      <div className="flex flex-col items-end gap-2">
        <button
          onClick={() => setShowCreateDataSlice(true)}
          className="flex items-center gap-2 px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
        >
          <span>Create Data Slice</span>
        </button>
        <div className="text-sm text-gray-500 max-w-md text-right">
          <p className="mb-5">Manage this specific subset of files.</p>
          <p className="text-xs bg-gray-100 p-2 rounded border-l-2 border-primary mt-1 text-left">
            <span className="font-medium block text-sm mb-1">
              How to use filter logic:
            </span>
            <span className="block mb-1">
              • <b>Filter Groups</b>: Each group contains one or more filter
              conditions
            </span>
            <span className="block mb-1">
              • <b>Between Groups</b>: Switch between "ALL groups must match"
              (AND) or "ANY group can match" (OR)
            </span>
            <span className="block">
              • <b>Within Groups</b>: For each group, toggle between "ALL
              conditions must match" (AND) or "ANY condition can match" (OR)
            </span>
          </p>
        </div>
      </div>
    );
  };

  const handleFetchChunkEvidence = (fileName, chunkId, tagName, value) => {
    fetchChunkEvidence({
      fileName,
      chunkId,
      tagName,
      value,
      chunkData,
      deasyUserConfig,
      setChunkData,
      setError,
      setActiveEvidence,
      setLoadingEvidenceMap,
    });
  };

  const renderEvidenceRow = (chunk, chunkId, row) => (
    <EvidenceRow
      chunk={chunk}
      chunkId={chunkId}
      activeEvidence={activeEvidence}
      activeFullText={activeFullText}
      evidenceRefs={evidenceRefs}
      toggleChunkText={toggleChunkText}
      TABLE_COLUMN_WIDTHS={TABLE_COLUMN_WIDTHS}
    />
  );

  const handleSelectDataSliceChange = (selected) => {
    handleDataSliceChange({
      selected,
      onFiltersChange,
      setExpandedSections,
      setAvailableColumns,
      setSelectedColumns,
      setSelectedDataSlice,
      deasyFileKey,
    });
  };

  // Add an effect to handle closing the Active Filters section when no filters exist
  useEffect(() => {
    if (Object.keys(backendFilters).length === 0) {
      setExpandedSections((prev) => ({
        ...prev,
        filters: false,
      }));
    }
  }, [backendFilters]);

  // Add useEffect for handling click outside evidence chunks
  useEffect(() => {
    if (!activeEvidence) return;

    const handleClickOutside = (event) => {
      // Check if click is outside any evidence chunk
      const isClickInEvidence = Array.from(evidenceRefs.values()).some(
        (ref) => ref && ref.contains(event.target),
      );

      if (!isClickInEvidence) {
        setActiveEvidence(null);
        setActiveFullText(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeEvidence, evidenceRefs]);

  // Add this function to handle standardize panel state
  const handleStandardizePanelState = (column, stats) => {
    setActiveStandardizeColumn(column);
    setStandardizeTagStats(stats);
  };

  const handleTagClick = (tagName, value, fileName) => {
    setTagEvidence({
      isOpen: true,
      tagName,
      value,
      fileName,
    });
  };

  const handleCloseTagEvidence = () => {
    setTagEvidence({
      isOpen: false,
      tagName: null,
      value: null,
      fileName: null,
    });
  };

  // Add this function to handle the "Add Schema Columns" button click
  const handleAddSchemaColumns = () => {
    if (!selectedDiscoveryGraph || selectedDiscoveryGraph.graph_id === null) {
      toast.error("Please select a metadata schema first");
      return;
    }

    const schemaTags = extractTagsFromSchema(selectedDiscoveryGraph.graph_data);

    // Find intersection between schema tags and available columns
    const columnsToAdd = schemaTags.filter(
      (tag) => availableColumns.includes(tag) && !selectedColumns.includes(tag),
    );

    if (columnsToAdd.length === 0) {
      toast(
        "All schema columns are already selected or have not been tagged in current data",
      );
      return;
    }

    // Add the schema tags to selected columns
    setSelectedColumns((prev) => [...prev, ...columnsToAdd]);
    toast.success(
      `Added ${columnsToAdd.length} columns from schema "${selectedDiscoveryGraph.graph_name}"`,
    );
  };

  useEffect(
    () => {
      const fetchDistributionsAndMetadata = async () => {
        if (!deasyUserConfig?.deasyApiKey) {
          return;
        }

        try {
          setLoadingDistributions(true);
          setLoading(true);
          setError(null);

          const nodeConditions = nodeData
            ? nodePathToConditions(nodeData.nodePath)
            : null;

          const getPaginatedDistributions = async () => {
            // Note: Limit is the number of values per tag, not the total number of values
            let maxValPerTag = 500;
            // By maxValPerTag, we mean the number of values per tag (truncating on the backend)

            const response = await metadataService.getDistributions(
              vdbLastActiveProfileName,
              deasyUserConfig.deasyApiKey,
              dataslice_id,
              nodeConditions,
              maxValPerTag,
              conditionsNew,
              selectedColumns.filter(
                (column) => !column.includes(deasyFileKey),
              ),
            );
            return response.data.distributions;
          };
          // Fetch all data in parallel with dataslice_id

          const [globalResponse, filteredMetadataResponse, uniqueTagsResponse] =
            await Promise.all([
              getPaginatedDistributions(),
              metadataService.getFilteredMetadata(
                vdbLastActiveProfileName,
                page * rowsPerPage,
                rowsPerPage,
                deasyUserConfig.deasyApiKey,
                dataslice_id,
                nodeConditions,
                searchQuery,
                conditionsNew,
              ),
              metadataService.getUniqueTags(
                deasyUserConfig.deasyApiKey,
                null,
                vdbLastActiveProfileName,
                dataslice_id,
                nodeConditions,
              ),
            ]);

          if (!isMounted.current) return;

          if (uniqueTagsResponse?.data?.tags) {
            const formattedTags = uniqueTagsResponse.data.tags;
            setAvailableColumns(formattedTags);
          }
          if (globalResponse && Object.keys(globalResponse).length === 0) {
            // Don't return early, just set distribution-related states
            if (!compact) {
              setTotalRows(0);
              setHasMore(false);
              setFilteredDistributions({});
              setGlobalDistributions({});
              setLoadingDistributions(false);
              // We'll continue to process metadata below
            }
          }

          if (globalResponse) {
            setGlobalDistributions(globalResponse);
            setFilteredDistributions(globalResponse);
          } else {
            // Ensure we set empty distributions even if globalResponse is null or undefined
            setGlobalDistributions({});
            setFilteredDistributions({});
          }

          if (filteredMetadataResponse?.data?.metadata) {
            const transformedData = Object.entries(
              filteredMetadataResponse.data.metadata,
            ).map(([fileName, metadata]) => ({
              [deasyFileKey]: fileName,
              ...metadata,
              chunks: [],
            }));

            // Ensure we set the table data with a small delay to avoid race conditions
            setTimeout(() => {
              if (isMounted.current) {
                setTableData(transformedData);
                setTotalRows(filteredMetadataResponse?.data?.total_matches);
                setHasMore(
                  filteredMetadataResponse?.data?.next_offset !== null,
                );
              }
            }, 0);

            // Calculate filtered distributions
            const distributions = {};
            transformedData.forEach((row) => {
              Object.entries(row).forEach(([key, value]) => {
                if (key !== deasyFileKey && key !== "chunks") {
                  if (!distributions[key]) distributions[key] = {};

                  // Handle array values by counting each element separately
                  if (Array.isArray(value)) {
                    value.forEach((item) => {
                      const processedItem = processTagValue(item);
                      if (!distributions[key][processedItem]) {
                        distributions[key][processedItem] = 0;
                      }
                      distributions[key][processedItem]++;
                    });
                  } else {
                    // Handle non-array values
                    const processedValue = processTagValue(value);
                    if (!distributions[key][processedValue]) {
                      distributions[key][processedValue] = 0;
                    }
                    distributions[key][processedValue]++;
                  }
                }
              });
            });
            setFilteredDistributions(distributions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          Sentry.captureException(error);
          if (isMounted.current) {
            setError(error.message || "Failed to fetch metadata");
          }
        } finally {
          setLoading(false);
          if (isMounted.current) {
            setLoadingDistributions(false);
          }
        }
      };

      fetchDistributionsAndMetadata();
    },
    // eslint-disable-next-line
    [
      deasyUserConfig,
      page,
      searchQuery,
      rowsPerPage,
      refreshKey,
      backendFilters,
      dataslice_id,
      compact,
      nodeData,
      vector_db_config,
      conditionsNew,
      selectedColumns,
    ],
  );

  // Cleanup function
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFilterClick = async (event, column) => {
    setAnchorEl(event.currentTarget);
    setActiveColumn(column);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setActiveColumn(null);
  };

  const handleFilterSelect = async (valueOrObject) => {
    if (!activeColumn) return;

    // Check if we're receiving a deselection action object
    if (
      typeof valueOrObject === "object" &&
      valueOrObject !== null &&
      valueOrObject.action === "remove"
    ) {
      // This is a deselection from the filter menu
      const { column, value } = valueOrObject;
      // Use the existing handleRemoveFilter function which already has all the necessary logic
      handleRemoveFilter(column, value);
      return;
    }

    // Original functionality for adding a single value
    const newFilters = { ...backendFilters };
    if (!newFilters[activeColumn]) {
      newFilters[activeColumn] = [];
    }

    // Check if this is a value with an operator
    if (
      typeof valueOrObject === "object" &&
      valueOrObject.value &&
      valueOrObject.operator
    ) {
      // Check if we already have this exact filter
      const existingFilterIndex = newFilters[activeColumn].findIndex((v) => {
        if (typeof v === "object" && v.value && v.operator) {
          return (
            v.value === valueOrObject.value &&
            v.operator === valueOrObject.operator
          );
        }
        return false;
      });

      if (existingFilterIndex === -1) {
        newFilters[activeColumn] = [...newFilters[activeColumn], valueOrObject];
      }
    } else if (!newFilters[activeColumn].includes(valueOrObject)) {
      newFilters[activeColumn] = [...newFilters[activeColumn], valueOrObject];
    }

    onFiltersChange(newFilters);

    setExpandedSections((prev) => ({
      ...prev,
      filters: true,
    }));
  };

  if (tableData.length === 0 && !compact && !loading) {
    if (Object.keys(backendFilters).length === 0) {
      return (
        <BlankMetadataScreen
          onStartExtraction={() =>
            setCurrentScreen(WorkflowType.EXTRACT_METADATA)
          }
        />
      );
    }
  }

  return (
    <div className="flex flex-col w-full relative">
      {/* Loading overlay */}
      {isConfigRefreshing && (
        <div className="absolute inset-0 bg-white/80 z-50 flex items-center justify-center">
          <div className="flex flex-col items-center gap-3">
            <CircularProgress size={24} className="text-primary" />
            <span className="text-sm text-gray-600">Updating data...</span>
          </div>
        </div>
      )}

      {/* Controls sections - Now positioned absolutely */}
      <div className="flex flex-row gap-6 justify-between w-full relative z-30">
        {/* Add Columns Section */}
        <div className="mb-4">
          <ExpandableSection
            title="Add Columns"
            icon={<FaPlus />}
            isExpanded={debouncedExpandedSections.controls}
            onToggle={() => handleSectionToggle("controls")}
            description="Choose what metadata columns to display"
            closeOnClickOutside
          >
            <div
              className={`space-y-4 max-h-[70vh] overflow-y-auto bg-white rounded-lg border border-gray-200 shadow-lg absolute top-full left-0 w-[40vw] ${!debouncedExpandedSections.controls ? "hidden" : ""}`}
            >
              {/* Column Selection Section */}
              <div className="space-y-2 p-6">
                <div className="rounded-lg">
                  <div className="flex flex-row gap-3 items-center justify-between w-full mb-4">
                    <div className="flex flex-col items-left gap-2">
                      <span className="text-xs font-medium text-gray-700 text-left">
                        View metadata from schema
                      </span>
                      <div className="flex items-center gap-2">
                        <GraphSelector viewOnly={true} verbose={false} />
                        {selectedDiscoveryGraph &&
                          selectedDiscoveryGraph.graph_id !== null && (
                            <button
                              onClick={handleAddSchemaColumns}
                              className="px-3 py-2.5 text-xs text-left rounded-md bg-primary text-white hover:bg-primary/90 transition-colors whitespace-nowrap"
                              title={`Add columns from schema: ${selectedDiscoveryGraph.graph_name}`}
                            >
                              <div className="flex items-center gap-1">
                                Select Metadata from{" "}
                                {selectedDiscoveryGraph.graph_name}
                              </div>
                            </button>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col items-left gap-2">
                      <span className="text-xs font-medium text-gray-700 text-left">
                        Apply data slices
                      </span>
                      <CompactDataSliceSelector
                        selectedDataSlice={selectedDataSlice}
                        onSelect={handleSelectDataSliceChange}
                        vdbFilesCount={vdbFilesCount}
                        dataSlices={dataSlices}
                        deasyUserConfig={deasyUserConfig}
                        tableHeader={true}
                      />
                    </div>
                  </div>
                  <MetadataColumnSelector
                    {...columnSelectorProps}
                    className="max-h-[25vh] overflow-y-auto"
                    key={refreshKey}
                    onAddSchemaColumns={handleAddSchemaColumns}
                    totalRows={totalRows}
                  />
                </div>
              </div>

              {/* Clear Filters Button */}
              {Object.keys(backendFilters).length > 0 && (
                <div className="pt-2">
                  <button
                    onClick={() => {
                      handleClearFilters();
                    }}
                    className="w-full flex items-center justify-center gap-2 px-4 py-2 text-sm bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors"
                  >
                    <FaSync
                      className={`w-3 h-3 ${loading ? "animate-spin" : ""}`}
                    />
                    <span>Clear All Filters</span>
                  </button>
                </div>
              )}
            </div>
          </ExpandableSection>
        </div>

        {/* Active Filters Section */}
        <div className="mb-4">
          <ExpandableSection
            title="Active Filters"
            icon={<FaFilter />}
            isExpanded={debouncedExpandedSections.filters}
            onToggle={() => handleSectionToggle("filters")}
            description="View and manage filters"
            closeOnClickOutside
          >
            <div
              className={`max-h-[70vh] w-[40vw] overflow-y-auto p-4 bg-white rounded-lg border border-gray-200 shadow-lg absolute top-full right-0 ${!debouncedExpandedSections.filters ? "hidden" : ""}`}
            >
              <div className="flex flex-col gap-4 w-full">
                {/* Top section with filters and filter logic */}
                <div className="flex justify-between items-start">
                  <div className="w-2/3 pr-4">
                    <ActiveFilters
                      filters={backendFilters}
                      onRemove={handleRemoveFilter}
                      conditionals_new={conditionsNew}
                      setConditionalsNew={setConditionsNew}
                      setRefreshKey={setRefreshKey}
                    />
                  </div>

                  <div className="w-1/3 flex flex-col items-end gap-4">
                    <CreateDataSliceButton />
                  </div>
                </div>

                {Object.keys(backendFilters).length > 0 && (
                  <button
                    onClick={() => {
                      handleClearFilters();
                      setSelectedDataSlice(null);
                      setExpandedSections((prev) => ({
                        ...prev,
                        filters: false,
                      }));
                    }}
                    className="flex items-center justify-center gap-2 px-4 py-2 text-sm bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors"
                  >
                    <FaSync
                      className={`w-3 h-3 ${loading ? "animate-spin" : ""}`}
                    />
                    <span>Clear All Filters</span>
                  </button>
                )}
              </div>
            </div>
          </ExpandableSection>
        </div>
      </div>

      {/* Standardize Panel */}
      {activeStandardizeColumn && (
        <div className="mb-4 w-full">
          <StandardizeValuesPanel
            isOpen={!!activeStandardizeColumn}
            onClose={() => handleStandardizePanelState(null, null)}
            tagsDetails={(() => {
              const tagDetails = savedTags.find(
                (tag) => tag.name === activeStandardizeColumn,
              );
              if (!tagDetails) {
                toast.error(
                  "This tag needs to be defined in the Metadata Library before it can be standardized. Please add it to the Metadata Library first.",
                );
                handleStandardizePanelState(null, null);
                return null;
              }
              return tagDetails;
            })()}
          />
        </div>
      )}

      {/* Existing MetadataTable content */}
      <div className="flex flex-col w-full">
        {/* Error message */}
        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg text-sm">
            <p className="font-medium">Error: {error}</p>
          </div>
        )}

        {/* Table section */}
        <Paper
          className="metadata-table-paper"
          sx={{
            boxShadow: "0 1px 1px rgba(0,0,0,0.01)",
            border: "2px solid #d1d5db",
            borderRadius: "8px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            minHeight: compact ? "auto" : "fit-content",
            overflowX: "auto",
            "&::WebkitScrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <TableContainer
            sx={{
              flex: 1,
              overflow: "auto",
              position: "relative",
              maxHeight: compact ? "400px" : "calc(100vh - 100px)",
              "&::WebkitScrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              "& .MuiTableCell-root": {
                textRendering: "optimizeLegibility",
                WebkitFontSmoothing: "antialiased",
                MozOsxFontSmoothing: "grayscale",
              },
              "& .MuiTableCell-head": {
                backgroundColor: "#f9fafb",
                fontWeight: "600",
              },
              "& .filename-column": {
                fontWeight: "500",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "340px",
                letterSpacing: "0.01em",
                padding: "8px 16px",
              },
            }}
          >
            <Table stickyHeader>
              <TableHeader
                {...{
                  displayColumns: displayColumns,
                  deasyFileKey: deasyFileKey,
                  loading: loading,
                  totalRows: totalRows,
                  handleRefresh: handleRefresh,
                  inputSearchQuery: inputSearchQuery,
                  setInputSearchQuery: setInputSearchQuery,
                  setPage: setPage,
                  setSearchQuery: setSearchQuery,
                  handleFilterClick: handleFilterClick,
                  effectiveFilters: backendFilters,
                  renderDistributionChart: renderDistributionChart,
                  onStandardizeClick: handleStandardizePanelState,
                  availableColumns: effectiveAvailableColumns,
                  setAvailableColumns: setAvailableColumns,
                }}
              />
              <TableBody
                tableData={tableData}
                displayColumns={displayColumns}
                deasyFileKey={deasyFileKey}
                TABLE_COLUMN_WIDTHS={TABLE_COLUMN_WIDTHS}
                chunkState={{
                  expandedRow,
                  loadingChunks,
                  chunkData,
                  setExpandedRow,
                  setLoadingChunks,
                  setChunkData,
                }}
                evidenceState={{
                  activeFullText,
                  activeEvidence,
                  showEvidence,
                  toggleChunkText,
                  handleFetchChunkEvidence,
                  setShowEvidence,
                  renderEvidenceRow,
                  loadingEvidenceMap,
                }}
                processTagValue={processTagValue}
                setError={setError}
                deasyUserConfig={deasyUserConfig}
                onTagClick={handleTagClick}
              />
            </Table>
          </TableContainer>

          {/* Sticky pagination */}
          <TablePagination
            component="div"
            rowsPerPageOptions={[25, 50, 75, 100]}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={totalRows}
            labelDisplayedRows={({ from, to }) =>
              tableData.length ? `${from}-${to} of ${totalRows}` : "0-0"
            }
            nextIconButtonProps={{ disabled: !hasMore || loading }}
            showFirstButton
            showLastButton
            sx={{
              borderTop: "1px solid #E5E7EB",
              backgroundColor: "#FAFAFA",
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 2,
              "& .MuiToolbar-root": {
                minHeight: "48px",
                padding: "0 16px",
              },
              "& .MuiTablePagination-select": {
                padding: "8px",
              },
              "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
                {
                  fontSize: "0.875rem",
                  color: "#374151",
                },
              "& .MuiTablePagination-actions": {
                marginLeft: "20px",
                "& .MuiIconButton-root": {
                  padding: "8px",
                  color: "#374151",
                  "&.Mui-disabled": {
                    color: "#D1D5DB",
                  },
                },
              },
            }}
          />
        </Paper>

        <FilterMenu
          anchorEl={anchorEl}
          onClose={handleFilterClose}
          activeColumn={activeColumn}
          effectiveFilters={backendFilters}
          onClearFilters={handleClearFilters}
          filterSearch={filterSearch}
          onFilterSearchChange={setFilterSearch}
          loadingValues={loadingValues}
          vector_db_config={vector_db_config}
          onFilterSelect={handleFilterSelect}
        />

        {showCreateDataSlice && (
          <CreateDataSlice
            isOpen={showCreateDataSlice}
            onClose={() => setShowCreateDataSlice(false)}
            conditions={conditionsNew}
            fileCount={totalRows}
            onSuccess={(dataSlice) => {
              setSelectedDataSlice(dataSlice);
              setCurrentScreen(WorkflowType.DATA_SLICE_MANAGEMENT);
            }}
          />
        )}

        <DeleteMetadataConfirmationModal
          showConfirmModal={showDeleteModal}
          setShowConfirmModal={setShowDeleteModal}
          selectedTagsToDelete={tagsToDelete}
          deleteStats={deleteStats}
          vdbProfileName={vdbLastActiveProfileName}
          deasyApiKey={deasyUserConfig.deasyApiKey}
          setRefreshKey={setRefreshKey}
        />
      </div>

      {/* File-level TagEvidence Modal */}
      {tagEvidence.isOpen && (
        <div
          className="fixed inset-0 bg-black/30 z-50 flex items-center justify-center"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              handleCloseTagEvidence();
            }
          }}
        >
          <div className="bg-white rounded-lg shadow-xl w-[1024px] max-h-[90vh] overflow-y-auto">
            <TagEvidence
              tagName={tagEvidence.tagName}
              value={tagEvidence.value}
              fileName={tagEvidence.fileName}
              onClose={handleCloseTagEvidence}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MetadataTable;
