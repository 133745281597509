import React, { useRef, useState, useEffect, useContext } from "react";
import {
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  Checkbox,
  ListItemText,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";
import { prepareVectorDBConfiguration } from "../../../../../../services/utils";
import {
  FaSync,
  FaSearch,
  FaCalculator,
  FaCalendarAlt,
  FaFont,
} from "react-icons/fa";
import { metadataService } from "../../../../../../services/api";
import { BaseContext } from "../../../../../../contexts/BaseContext";

// Define operators enum to match backend
const OPERATORS = {
  ANY: "any",
  ALL: "all",
  GE: "ge",
  GT: "gt",
  LE: "le",
  LT: "lt",
};

// Define operator display names
const OPERATOR_LABELS = {
  [OPERATORS.ANY]: "Any of (OR)",
  [OPERATORS.ALL]: "All of (AND)",
  [OPERATORS.GE]: "Greater than or equal to (≥)",
  [OPERATORS.GT]: "Greater than (>)",
  [OPERATORS.LE]: "Less than or equal to (≤)",
  [OPERATORS.LT]: "Less than (<)",
};

const FilterMenu = ({
  anchorEl,
  onClose,
  activeColumn,
  effectiveFilters,
  onClearFilters,
  filterSearch,
  onFilterSearchChange,
  loadingValues,
  onFilterSelect,
  vector_db_config,
}) => {
  const [filteredValues, setFilteredValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [prevSearchText, setPrevSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState(OPERATORS.ANY);
  const [filterValue, setFilterValue] = useState("");
  const [columnType, setColumnType] = useState("string");

  const { deasyUserConfig, savedTags, vdbLastActiveProfileName } =
    useContext(BaseContext);

  const activeVdbConfig =
    vector_db_config ||
    prepareVectorDBConfiguration(
      deasyUserConfig.vdbmConfig?.Configs?.[vdbLastActiveProfileName],
    ) ||
    {};

  const PAGE_SIZE = 10;

  const listInnerRef = useRef();

  // Determine column type from savedTags when active column changes
  useEffect(() => {
    if (!activeColumn || !savedTags || !savedTags.length) return;

    const tagDefinition = savedTags.find((tag) => tag.name === activeColumn);
    if (tagDefinition) {
      setColumnType(tagDefinition.output_type || "string");
      // Default to ANY for string types, GE for number/date types
      setSelectedOperator(
        ["number", "date"].includes(tagDefinition.output_type)
          ? OPERATORS.GE
          : OPERATORS.ANY,
      );
    } else {
      setColumnType("string");
      setSelectedOperator(OPERATORS.ANY);
    }
  }, [activeColumn, savedTags]);

  const handleSearch = async (searchText) => {
    setFilteredValues([]);
    setIsLoading(true);
    if (searchText.length < prevSearchText.length) {
      setSearchPage(1);
    }
    const resp = await metadataService.getDistinctValuesPaginated(
      activeVdbConfig.name,
      deasyUserConfig.deasyApiKey,
      activeColumn,
      searchPage,
      PAGE_SIZE,
      searchText,
    );
    setSearchPage(searchPage + 1);
    setFilteredValues(resp.data.values);
    setPrevSearchText(searchText);
    setIsLoading(false);
    return resp;
  };

  const paginatedSearch = async () => {
    const resp = await metadataService.getDistinctValuesPaginated(
      activeVdbConfig.name,
      deasyUserConfig.deasyApiKey,
      activeColumn,
      currentPage,
      PAGE_SIZE,
    );
    setCurrentPage(currentPage + 1);
    setFilteredValues([...filteredValues, ...resp.data.values]);
  };

  useEffect(() => {
    if (filterSearch !== null && filterSearch !== "" && activeColumn) {
      handleSearch(filterSearch);
      setCurrentPage(1);
    } else if (activeColumn) {
      setFilteredValues([]);
      paginatedSearch();
    }
    // eslint-disable-next-line
  }, [filterSearch, activeColumn]);

  const handleScroll = async () => {
    if (activeColumn && listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (filterSearch !== null && filterSearch !== "") {
          await handleSearch(filterSearch);
        } else {
          await paginatedSearch();
        }
      }
    }
  };

  // Handle direct numeric/date input and apply filter with operator
  const handleApplyOperatorFilter = () => {
    if (!filterValue) return;

    // Pass value with operator for all operator types
    onFilterSelect({
      value: filterValue,
      operator: selectedOperator,
    });

    // Clear input after successful application
    setFilterValue("");
  };

  // Handle toggle filter to either add or remove a value
  const handleToggleFilter = (value) => {
    const isCurrentlySelected = effectiveFilters[activeColumn]?.some((v) => {
      if (typeof v === "string") return v === value;
      if (typeof v === "object" && v.value) return v.value === value;
      return false;
    });

    if (isCurrentlySelected) {
      // If already selected, we want to remove this value (deselect)
      onFilterSelect({ column: activeColumn, value: value, action: "remove" });
    } else {
      // If not selected, add it with appropriate operator
      // For numeric and date types, always include the operator
      if (["number", "date"].includes(columnType)) {
        onFilterSelect({
          value: value,
          operator: selectedOperator,
        });
      } else {
        // For string types, just use the value
        onFilterSelect(value);
      }
    }
  };

  // Get the appropriate icon for the column type
  const getColumnIcon = () => {
    switch (columnType) {
      case "number":
        return <FaCalculator className="text-blue-500 mr-1" />;
      case "date":
        return <FaCalendarAlt className="text-green-500 mr-1" />;
      default:
        return <FaFont className="text-gray-500 mr-1" />;
    }
  };

  // Determine which operators should be shown based on column type
  const availableOperators = ["number", "date"].includes(columnType)
    ? Object.values(OPERATORS)
    : [OPERATORS.ANY, OPERATORS.ALL];

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => {
        setFilteredValues([]);
        setCurrentPage(1);
        setSearchPage(1);
        setIsLoading(false);
        setFilterValue("");
        onClose();
      }}
      PaperProps={{
        elevation: 2,
        sx: {
          maxHeight: 400,
          minWidth: 300,
          width: "auto",
          borderRadius: "8px",
          mt: 1,
          "& .MuiMenuItem-root:hover": {
            backgroundColor: "#f9fafb",
          },
        },
      }}
    >
      <div className="px-3 py-2 border-b border-gray-100">
        <div className="flex items-center justify-between mb-2">
          <Typography
            variant="subtitle2"
            sx={{ color: "#374151", display: "flex", alignItems: "center" }}
          >
            {getColumnIcon()}
            Filter by {activeColumn}
          </Typography>
          <Tooltip title="Clear filters">
            <IconButton
              size="small"
              onClick={onClearFilters}
              disabled={!effectiveFilters[activeColumn]}
            >
              <FaSync style={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>
        </div>

        {/* Operator selection for numeric and date types */}
        {["number", "date"].includes(columnType) && (
          <div className="mb-2">
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel
                id="operator-select-label"
                sx={{
                  "&.Mui-focused": {
                    color: "#4FA892",
                  },
                }}
              >
                Operator
              </InputLabel>
              <Select
                labelId="operator-select-label"
                id="operator-select"
                value={selectedOperator}
                onChange={(e) => setSelectedOperator(e.target.value)}
                label="Operator"
                className="mb-2"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4FA892",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                  },
                  "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4FA892",
                  },
                  "& .MuiSelect-icon": {
                    color: "rgba(0, 0, 0, 0.54)",
                  },
                  "&.Mui-focused .MuiSelect-icon": {
                    color: "#4FA892",
                  },
                }}
              >
                {availableOperators.map((op) => (
                  <MenuItem key={op} value={op}>
                    {OPERATOR_LABELS[op]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        {/* Show direct value input for comparison operators */}
        {["number", "date"].includes(columnType) &&
          !["any", "all"].includes(selectedOperator) && (
            <div className="mb-3">
              <TextField
                fullWidth
                label={columnType === "number" ? "Enter number" : "Enter date"}
                variant="outlined"
                size="small"
                type={columnType === "number" ? "number" : "date"}
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                className="mb-2"
                color="primary"
                InputLabelProps={{
                  shrink: true,
                  sx:
                    columnType === "date"
                      ? {
                          transform: "translate(14px, -9px) scale(0.75)",
                        }
                      : undefined,
                }}
                InputProps={{
                  sx:
                    columnType === "date"
                      ? {
                          "&::-webkit-calendar-picker-indicator": {
                            marginRight: "4px",
                            cursor: "pointer",
                            color: "#4FA892",
                          },
                        }
                      : undefined,
                }}
                sx={{
                  marginTop: columnType === "date" ? "8px" : "0px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#4FA892",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#4FA892",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: columnType === "date" ? "8.5px 14px" : undefined,
                  },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={handleApplyOperatorFilter}
                disabled={!filterValue}
                sx={{
                  backgroundColor: "#4FA892",
                  "&:hover": {
                    backgroundColor: "#3E8A75",
                  },
                  marginTop: "8px",
                  textTransform: "none",
                }}
              >
                Apply filter
              </Button>
            </div>
          )}

        {/* Search input for list selection */}
        {columnType === "string" && (
          <>
            <div className="relative mb-2">
              <FaSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 h-3 w-3" />
              <input
                type="text"
                value={filterSearch}
                onChange={(e) => {
                  onFilterSearchChange(e.target.value);
                  setSearchPage(1);
                }}
                placeholder="Search values..."
                className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary transition-colors"
              />
            </div>
            {/* Help text */}
            <div className="text-xs text-gray-500">
              {columnType === "string"
                ? "Select multiple values to filter with OR condition"
                : ["any", "all"].includes(selectedOperator)
                  ? "Select values or use operators for advanced filtering"
                  : `Filter rows where ${activeColumn} ${OPERATOR_LABELS[selectedOperator].split(" ")[0].toLowerCase()} your input`}
            </div>
          </>
        )}
      </div>

      {/* Value list section */}
      {(["any", "all"].includes(selectedOperator) || columnType === "string") &&
        (isLoading || loadingValues ? (
          <div className="flex justify-center p-3">
            <CircularProgress size={20} sx={{ color: "#6366F1" }} />
          </div>
        ) : filteredValues.length > 0 ? (
          <div
            style={{ maxHeight: 300, overflow: "auto" }}
            onScroll={handleScroll}
            ref={listInnerRef}
          >
            {filteredValues.map((value) => (
              <MenuItem
                key={value}
                onClick={() => handleToggleFilter(value)}
                sx={{
                  minHeight: "40px",
                  fontSize: "0.875rem",
                  padding: "4px 8px",
                  "&:hover": {
                    backgroundColor: "#F3F4F6",
                  },
                }}
              >
                <Checkbox
                  checked={Boolean(
                    effectiveFilters[activeColumn]?.some((v) => {
                      if (typeof v === "string") return v === value;
                      if (typeof v === "object" && v.value)
                        return v.value === value;
                      return false;
                    }),
                  )}
                  size="small"
                  sx={{
                    color: "#d1d5db",
                    "&.Mui-checked": {
                      color: "#4FA892",
                    },
                  }}
                />
                <ListItemText
                  primary={value}
                  primaryTypographyProps={{
                    fontSize: "0.875rem",
                    color: "#1F2937",
                  }}
                />
              </MenuItem>
            ))}
          </div>
        ) : (
          <MenuItem
            disabled
            sx={{
              minHeight: "40px",
              fontSize: "0.875rem",
              color: "#6B7280",
            }}
          >
            <ListItemText
              primary={filterSearch ? "No matching values" : "No values found"}
            />
          </MenuItem>
        ))}
    </Menu>
  );
};

export default FilterMenu;
