import { createContext, useState, useEffect } from "react";
import { FaChartLine, FaDatabase } from "react-icons/fa";
import { auth } from "../config/firebase";
import { config } from "../config/config";
import {
  sourceMetadataService,
  tagService,
  dataSliceService,
} from "../services/api";
import { DB_OPTIONS } from "../components/MainContent/DeasyConfig/ConfigElements/utils";

export const BaseContext = createContext({
  deasyUserConfig: {},
  savedTags: [],
  updatedTags: {},
  setDeasyUserConfig: () => {},
  setSavedTags: () => {},
  vdbFilesCount: null,
  setVdbFilesCount: () => {},
  setUpdatedTags: () => {},
});

export const BaseProvider = ({ children }) => {
  // Page configuration
  const activePageToData = {
    tokenDashboard: {
      title: "API keys and credits",
      helpDescription: "Manage your Deasy API keys and add credits",
      icon: <FaChartLine className="w-5 h-5" />,
    },
    vectorDatabase: {
      title: "Data Source",
      helpDescription: "Configure your Data Source",
      icon: <FaDatabase className="w-5 h-5" />,
    },
  };
  const disabledPages = config.features.disabledPages;
  // total config
  const [deasyUserConfig, setDeasyUserConfig] = useState({});
  const [vdbProfiles, setVdbProfiles] = useState({});
  const [llmProfiles, setLlmProfiles] = useState({});

  const [savedTags, setSavedTags] = useState([]);
  const [vdbFilesCount, setVdbFilesCount] = useState(null);
  const [loadingVdbFilesCount, setLoadingVdbFilesCount] = useState(false);

  //routing
  const [activePage, setActivePage] = useState("");
  const [currentScreen, setCurrentScreen] = useState(null);

  const [metadataHealth, setMetadataHealth] = useState(null);
  const [explorerLevelSelection, setExplorerLevelSelection] = useState("FILE");
  const [showUnique, setShowUnique] = useState(false);
  const [dataSliceStep, setDataSliceStep] = useState(0);
  const [dataSlices, setDataSlices] = useState([]);

  // Workflows
  const [refreshDataSlices, setRefreshDataSlices] = useState(false);
  const [selectedDataSlice, setSelectedDataSlice] = useState(null);
  const [dataSliceScreen, setDataSliceScreen] = useState(null);
  const [isConfigRefreshing, setIsConfigRefreshing] = useState(false);

  const [updatedTags, setUpdatedTags] = useState({});

  const [startingTagInTS, setStartingTagInTS] = useState(null);

  const refreshDataSlicesHook = () => {
    setRefreshDataSlices((prev) => !prev);
  };

  const deasyApiKey = deasyUserConfig?.deasyApiKey;
  const vdbLastActiveProfileName = deasyUserConfig?.vdbmConfig?.LastActive;
  const llmLastActiveProfileName = deasyUserConfig?.llmConfig?.LastActive;

  // Add parseSliceConditions before the useEffect that uses it
  const parseSliceConditions = (graphData) => {
    const conditions = {};

    const traverse = (obj, path = []) => {
      if (!obj || typeof obj !== "object") return;

      // If we find a value node (has parent tag with TagAvailableValues)
      if (path.length >= 2) {
        const tagName = path[path.length - 2];
        const value = path[path.length - 1];

        // Skip special nodes
        if (value === "TagAvailableValues") return;

        // Initialize array for this tag if it doesn't exist
        if (!conditions[tagName]) {
          conditions[tagName] = [];
        }

        // Add the value if it's not already included
        if (!conditions[tagName].includes(value)) {
          conditions[tagName].push(value);
        }
      }

      // Continue traversing
      Object.entries(obj).forEach(([key, value]) => {
        traverse(value, [...path, key]);
      });
    };

    traverse(graphData);
    return conditions;
  };

  // The useEffect that fetches dataSlices can now use parseSliceConditions
  useEffect(() => {
    if (!deasyApiKey) return;
    const fetchDataslices = async () => {
      try {
        const response = await dataSliceService.listDataslices(deasyApiKey);
        const dataSlices = response.map((slice) => ({
          ...slice,
          conditions: parseSliceConditions(slice.graph_data),
          conditions_new: slice.conditions_new,
          dataPoints: slice.dataPoints || 0,
          lastUpdated: slice.lastUpdated || new Date().toISOString(),
          collection_name:
            slice.vector_db_config?.collection_name || "Unnamed Collection",
        }));
        setDataSlices(dataSlices);
      } catch (error) {
        console.error("Failed to fetch data slices:", error);
        setDataSlices([]);
      }
    };

    fetchDataslices();
  }, [deasyApiKey, refreshDataSlices]);

  // Add effect to persist savedTags
  useEffect(() => {
    if (Array.isArray(savedTags)) {
      localStorage.setItem("savedTags", JSON.stringify(savedTags));
    }
  }, [savedTags]);

  // Add this effect to load tags when API key is available
  useEffect(() => {
    const loadTags = async () => {
      if (deasyUserConfig.deasyApiKey) {
        try {
          const response = await tagService.getSavedTags(
            deasyUserConfig.deasyApiKey,
          );
          // Ensure tuned property is always a number
          const processedTags = (response.data.tags || []).map((tag) => ({
            ...tag,
            tuned: typeof tag.tuned === "number" ? tag.tuned : 0,
          }));
          setSavedTags(processedTags);
        } catch (error) {
          console.error("Error loading tags:", error);
          setSavedTags([]);
        }
      }
    };

    loadTags();
  }, [deasyUserConfig.deasyApiKey]);

  // Utility functions
  const signOut = () => {
    auth.signOut();
  };

  const exportData = ({ type = "json" }) => {
    if (!savedTags?.length) return;

    if (type === "json") {
      const jsonData = savedTags.reduce((acc, tag) => {
        const { tag_id, username, created_at, updated_at, ...cleanedTag } = tag;
        acc[tag.name] = cleanedTag;
        return acc;
      }, {});

      const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "tags.json";
      a.click();
    }
  };

  const [currentNode, setCurrentNode] = useState(null);
  const [uniqueTags, setUniqueTags] = useState([]);

  const [metadataReadinessStatus, setMetadataReadinessStatus] = useState(false);
  const [unsyncedVDBFilesCount, setUnsyncedVDBFilesCount] = useState(0);

  const handleSaveMetadata = async (formData) => {
    if (!formData.name || !formData.description || !formData.output_type) {
      console.log("Please fill out all fields");
      return;
    }

    try {
      const dataToSave = {
        ...formData,
        output_type: formData.output_type || "word",
        available_values: formData.available_values || [],
        tuned: typeof formData.tuned === "number" ? formData.tuned : 0,
      };
      let response;
      if (formData.tag_id) {
        response = await tagService.updateTag(dataToSave, deasyApiKey);
      } else {
        response = await tagService.upsertTag(dataToSave, deasyApiKey);
      }

      // Only update if tags actually changed
      const tagsResponse = await tagService.getSavedTags(deasyApiKey);
      const newTags = tagsResponse.data.tags || [];
      const processedTags = newTags.map((tag) => ({
        ...tag,
        tuned: typeof tag.tuned === "number" ? tag.tuned : 0,
      }));

      // Only update state if tags changed
      setSavedTags((prev) => {
        const prevString = JSON.stringify(prev);
        const newString = JSON.stringify(processedTags);
        return prevString === newString ? prev : processedTags;
      });

      return response.data;
    } catch (error) {
      console.error("Error saving tag:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (deasyUserConfig.vdbmConfig && deasyApiKey) {
      fetchTotalVdbFiles(
        deasyUserConfig.vdbmConfig.Configs[
          deasyUserConfig.vdbmConfig.LastActive
        ],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deasyUserConfig.vdbmConfig]);

  const fetchTotalVdbFiles = async (vectorDBConfiguration) => {
    try {
      setLoadingVdbFilesCount(true);
      // Validate configuration first
      const dbOption = DB_OPTIONS.find(
        (opt) => opt.value === vectorDBConfiguration?.type,
      );
      const hasRequiredFields = dbOption?.fields
        .filter((field) => !field.toLowerCase().includes("key"))
        .every((field) => vectorDBConfiguration?.[field]);
      if (!dbOption || !hasRequiredFields) {
        return;
      }
      const response = await sourceMetadataService.getVdbTotalFiles(
        vectorDBConfiguration.name,
        deasyUserConfig.deasyApiKey,
      );
      setVdbFilesCount({
        total_files: response.data.total_files,
        profile: vectorDBConfiguration.type,
      });
    } catch (error) {
      console.error("Error fetching total VDB files:", error);
    } finally {
      setLoadingVdbFilesCount(false);
    }
  };

  return (
    <BaseContext.Provider
      value={{
        // Getters
        activePage,
        activePageToData,
        metadataHealth,
        savedTags,
        disabledPages,
        deasyUserConfig,
        explorerLevelSelection,
        currentNode,
        metadataReadinessStatus,
        unsyncedVDBFilesCount,
        showUnique,
        uniqueTags,
        vdbFilesCount,
        currentScreen,
        dataSliceStep,
        dataSlices,
        selectedDataSlice,
        dataSliceScreen,
        loadingVdbFilesCount,
        isConfigRefreshing,
        updatedTags,
        startingTagInTS,
        deasyApiKey,
        vdbLastActiveProfileName,
        llmLastActiveProfileName,
        vdbProfiles,
        llmProfiles,
        // Setters
        setActivePage,
        setMetadataHealth,
        setSavedTags,
        setDeasyUserConfig,
        setExplorerLevelSelection,
        setCurrentNode,
        setMetadataReadinessStatus,
        setUnsyncedVDBFilesCount,
        setShowUnique,
        setUniqueTags,
        setVdbFilesCount,
        setCurrentScreen,
        setDataSliceStep,
        setDataSlices,
        setSelectedDataSlice,
        setDataSliceScreen,
        setLoadingVdbFilesCount,
        setIsConfigRefreshing,
        setUpdatedTags,
        setStartingTagInTS,
        setVdbProfiles,
        setLlmProfiles,
        // Functions
        signOut,
        exportData,
        refreshDataSlicesHook,
        handleSaveMetadata,
      }}
    >
      {children}
    </BaseContext.Provider>
  );
};
